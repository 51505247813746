.select-container {
    display: flex;
    flex-direction: column;
}
.selected-value {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    text-transform: capitalize;
}
.selected {
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 2.26573px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    padding: 4px;
    &.member {
        background: rgba(0, 0, 0, 0.3);
    }
    &.admin {
        background: #000000;
    }
}

.expand-btn {
    width: 20px;
    height: 20px;
    cursor: pointer;
    & img {
        width: 14px;
        height: 6px;
    }
}
.list-div {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    z-index: 9;
}
.list-container {
    padding: 8px;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;

    & .option {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        padding: 11px 8px;
        text-transform: uppercase;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        row-gap: 6px;
        & span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            text-transform: capitalize;
            color: rgba(0, 0, 0, 0.5);
        }
        &:hover {
            background: #ececec;
            border-radius: 4px;
        }
    }
}
