.navbar-container {
  z-index: 1;
  top: 98px;
  left: 30px;
}
.navbar-new {
  width: 70px;
  border-radius: 18px;
  padding: 15px;
  background-color: rgba($color: #fff, $alpha: 0.8) !important;
  .nav-item {
    width: 80%;
    height: 68px;
    width: 68px;
    padding: 5px;
    margin: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    transform: scale(0.9);
    transition: all 0.15s ease-in-out;
    &:first-child {
      margin: -5px;
      margin-bottom: 20px;
    }
    &:last-child {
      margin: -5px;
      margin-top: 20px;
    }
    &:hover {
      background-color: #000;
      transform: scale(0.95);
      .nav-text {
        color: white !important;
      }
    }
    &:active {
      transform: scale(0.93);
    }
    .nav-text {
      font-weight: bold;
      color: grey;
      font-size: 9px;
    }
  }
  .selected {
    background-color: #000;
    box-shadow: 0px 13px 25px rgba(0, 0, 0, 0.15);
    .nav-text {
      color: white !important;
    }
    &:hover {
      transform: scale(0.9) !important;
    }
  }
}

//Tooltip - widget installation check
//alert container css
.alert-container {
  background-color: #ff9e0c;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  width: 385px;
  min-height: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  margin-left: 30px;
  bottom: 25px;
  border-radius: 4px;
  padding-left: 10px;
  z-index: 9;
}

/* Text inside the alert */

.alert-container > p {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  display: flex;
  border-radius: 4px;
}

/* Button inside the alert */

.installBtn {
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #000;
  border-radius: 4px;
  width: 81px;
  height: 20px;
  padding: 8px 10px;
  border: none;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 800;
  justify-content: center;
  cursor: pointer;
}

/* alert triangle on the left */

.alert-container:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #ff9e0c;
  left: -8px;
  top: 19px;
}

.nav-item.disable-nav-item {
  cursor: not-allowed;
  &:hover{
    background-color: rgba($color: #fff, $alpha: 0.8) !important;
    transform: scale(0.9) !important;
    .nav-text {
      color: gray !important;
    }
  }
}