.main-dash-container {
    justify-content: center;
    padding-left: 180px;
    padding-right: 50px;
    overflow: hidden;
    padding-top: 68px;
    min-height: calc(100vh - 68px);
    .content-width {
        width: 100%;
        height: calc(100vh - 88px);
    }
}

@media only screen and (min-width: 1500px) and (max-device-width: 1600px) {
    .main-dash-container {
        .content-width {
            width: 94%;
        }
    }
}

@media only screen and (min-width: 1601px) and (max-device-width: 1800px) {
    .main-dash-container {
        .content-width {
            width: 88%;
        }
    }
}

@media only screen and (min-width: 1800px) and (max-device-width: 1900px) {
    .main-dash-container {
        .content-width {
            width: 76%;
        }
    }
}

@media only screen and (min-width: 1900px) and (max-device-width: 2100px) {
    .main-dash-container {
        .content-width {
            width: 80%;
        }
    }
}
@media only screen and (min-width: 2100px) and (max-device-width: 2300px) {
    .main-dash-container {
        .content-width {
            width: 70%;
        }
    }
}

@media only screen and (min-width: 2300px) and (max-device-width: 2500px) {
    .main-dash-container {
        .content-width {
            width: 60%;
        }
    }
}

@media only screen and (min-width: 2500px) and (max-device-width: 2700px) {
    .main-dash-container {
        .content-width {
            width: 55%;
        }
    }
}

@media only screen and (min-width: 2700px) and (max-device-width: 2900px) {
    .main-dash-container {
        .content-width {
            width: 53%;
        }
    }
}

@media only screen and (min-width: 2900px) and (max-device-width: 3100px) {
    .main-dash-container {
        .content-width {
            width: 52%;
        }
    }
}

.space-count {
    height: 16px;
    background-color: rgba(255, 255, 255, 0.8);
    font-weight: 700;
    border-radius: 3px;
    display: inline-block;
    padding: 3px 6px 0px 6px;
    cursor: default;
}

.products-heading {
    h1 {
        margin-top: 9px;
        overflow: hidden;
        font-size: 18px;
    }
    .button-link {
        background-color: black;
        padding: 15px 20px;
        color: white;
        font-size: 12px;
        border-radius: 5px;
        transition: all 0.2s ease-in-out;
        font-weight: 700;
        text-transform: uppercase;
        box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 43px;
        img {
            height: 13px;
            margin-right: 10px;
        }
        &:hover {
            box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
        }
    }
    .disable {
        background-color: grey;
    }
}

.products-search-wrapper {
    button {
        transition: all 0.2s ease-in-out;
        border-radius: 3px;

        span {
            width: auto;
        }
    }
}

.active-products-wrapper {
    overflow: visible;
}

.active-list {
    height: calc(100vh - 188px);
    position: relative;
}

.publish-heading-container {
    align-items: center;
    justify-content: flex-start;
    h4 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 10px;
        cursor: pointer;
        min-height: 20px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
    }
}

.active-item-header {
    position: relative;
    z-index: 9;
    width: 100%;
    display: grid;
    grid-template-columns: 14fr 4fr 4fr 4fr 10fr 40px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 2px 0;
    &-search {
        margin: 0;
        display: flex;
        align-items: center;
        img {
            margin-left: 10px;
        }
        input {
            margin: 0;
            border: none;
            background-color: transparent;
            box-shadow: none;
            font-size: 12px;
            font-weight: 600;
            border-radius: 0;
            border: none;
            color: unset;
            border-bottom: 1px solid rgba(0, 0, 0, 0);
            width: 140%;
            transition: all 0.2s ease-in-out;
            &:hover {
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
        &-btn {
            position: relative;
            font-size: 12px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            cursor: pointer;
            > img {
                height: 20px;
                margin-left: 5px;
            }
            .filtered-agents-1 {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                max-width: 197px;
            }
            span.filtered-agents-other {
                font-size: 10px;
                background-color: rgba(0, 0, 0, 0.08);
                border-radius: 18px;
                margin-left: 5px;
                padding: 5px 10px;
            }
        }
    }
}

.loading-space {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 500px;
    font-size: 14px;
    font-weight: 600;
    color: #00000080;
}

// Pagination

// pagination Wrapper
.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

a {
    color: inherit;
}

// Paginate Container

.paginate-container {
    display: flex;
    align-items: center;
    margin-top: 30px !important;
    margin-bottom: 45px !important;
}
.page-item {
    height: 30px;
    width: 30px;
    margin: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: white;
    transition: all 0.15s ease-in;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.27);
    border-radius: 5px;
}

.page-item:hover {
    color: rgba(0, 0, 0, 0.67);
}

.page-item-active {
    background-color: black;
    color: white;
}

.page-item-active:hover {
    background-color: black;
    color: white;
}

.page-item-link {
    border: 0px;
    cursor: pointer;
    outline: none;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    // &:hover {
    //   background-color: black;
    //   color: white;
    //   transform: scale(1.1);
    // }
    // &:active {
    //   transform: scale(0.9);
    // }
    border-radius: 5px;
}

.next-previous-class {
    text-align: center;
    margin: 0px 15px;
    width: 30px;
    border-radius: 5px;
    transition: all 0.15s ease-in-out;
    border: 0px;
    cursor: pointer;
    outline: none;
}

.next-previous-link-class {
    outline: none;
    width: 30px;
    height: 30px;
    color: rgba(0, 0, 0, 0.27);
}

.disabled-class {
    cursor: not-allowed;
}

.prop-card-info-wrapper {
    display: none !important;
    &:first-child {
        display: block !important;
    }
    font-size: 8px;
    font-weight: 700;
    opacity: 0.2;
    .heading-info {
        width: 25%;
        margin-left: 40px;
    }
    .pairing-info {
        width: 10%;
    }
    .hashtags-info {
        width: 15%;
    }
    .videoswrapper-info {
        width: 15%;
    }
}

.search-hr {
    height: 0.71px;
    width: 106%;
    background-color: #000;
}

.paginate-container > li.break > a {
    color: rgba($color: #000000, $alpha: 0.27);
}

.edit-btn-wrapper {
    position: absolute;
    right: 0;
    &-on {
        &-selected {
            text-transform: uppercase;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.5);
            font-weight: 700;
        }
        &-pipe {
            background-color: #00000014;
            width: 1px;
            height: 26px;
        }
    }
}

.button-edit-link {
    background-color: transparent;
    padding: 7px 10px;
    color: rgba(0, 0, 0, 0.56);
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.56);
    transition: all 0.2s ease-in-out;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 16px;
        margin-right: 5px;
    }
}

.button-delete-link {
    background-color: transparent;
    padding: 7px 10px;
    color: #e00000;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #e00000;
    transition: all 0.2s ease-in-out;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        height: 16px;
        margin-right: 5px;
    }
}
button[disabled] {
    cursor: not-allowed !important;
}

button.button-link[disabled] {
    cursor: not-allowed;
    background-color: grey;
}

.button-done-link {
    background-color: #fff;
    padding: 8px 10px;
    color: rgba(0, 0, 0, 0.56);
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid #fff;
    transition: all 0.2s ease-in-out;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    img {
        height: 8px;
        margin-right: 5px;
    }
}

.dropdown-filter-wrapper {
    position: absolute;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    background-color: #fff;
    top: 33px;
    > ul {
        border-radius: 12px;
    }
    > ul li {
        color: #000;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        font-weight: 700;
        transition: all 0.1s ease-in-out;
    }
    > ul li:hover {
        background-color: rgba(0, 0, 0, 0.08);
    }
    > ul li:nth-child(2) {
        border-top: 1px solid #0000000f;
        border-bottom: 1px solid #0000000f;
    }
    > ul li.li-rent-sale {
        width: 120px;
    }
    > ul li.li-web-paired {
        width: 150px;
    }
    &-search {
        &-container {
            padding: 10px 20px 0px 20px;
        }
        img.filer-search-icon {
            position: absolute;
        }
        input {
            margin: 0;
            border: none;
            background-color: transparent;
            box-shadow: none;
            font-size: 12px;
            font-weight: 600;
            border-radius: 0;
            border: none;
            color: unset;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            width: 100%;
            padding: 0 20px;
        }
        ul li {
            position: relative;
            div.filter-agent-img-container {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;
                > img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            color: #000;
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            padding: 6px 19px;
            .filter-agent-img-container {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                width: 150px;
            }
        }
        ul li.active-search-agents:hover {
            background-color: #0000000d;
        }
    }
}
.filtered-agent-list {
    height: 20px;
    position: absolute;
    right: 8px;
    top: 7px;
}

.agent-list-overflow {
    max-height: 230px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.agent-list-overflow::-webkit-scrollbar {
    width: 4px !important;
    display: block !important;
}
.agent-list-overflow::-webkit-scrollbar-thumb {
    width: 4px !important;
    border-radius: 10px;
    background-color: #adadad;
}

.payment-loader {
    display: flex;
    justify-content: center;
    align-items: center;
}
