.btn-atom {
  width: 100%;
  height: 50px;
  background-color: black;
  color: white;
  font-family: "Montserrat";
  border-radius: 6px !important;
  font-size: 14px;
  outline: none;
  border: 0px;
  cursor: pointer;
  // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  }
  &:active {
    transform: scale(0.98);
  }
  &:disabled {
    background-color: #808080;
  }
}
