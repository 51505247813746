.popups-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(17.5px);
    -webkit-backdrop-filter: blur(17.5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    .space-card-wrapper {
        width: 900px;
        min-width: 800px;
        max-width: 950px;
        height: 796px !important;
        min-height: 680px;
        max-height: 796px !important;
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
        padding: 25px;
        border-radius: 6px;
        background: rgba(225, 225, 225, 0.9);
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 0 !important;
        overflow: hidden;
    }
}

.loading-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
