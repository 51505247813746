.invite {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  .form-card-wrapper-invite {
    width: 240px;
    padding: 35px 35px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(225, 225, 225, 0.9);
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
    position: relative;
  }
  p {
    font-size: 10px;
    line-height: 16px;
    font-weight: 600;
    font-style: normal;
    opacity: 0.8;
  }
  .image-wrapper-main {
    width: 56px;
    min-width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 100%;
    box-shadow: 0px 10px 30px 0px #00000040;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .image-wrapper-invite {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 100%;
    box-shadow: 0px 10px 30px 0px #00000040;
    &:first-child {
      margin-left: 0px;
    }
    img {
      width: 100%;
    }
  }
  .line {
    width: 100%;
    height: 1px;
    background-color: #e6e6e6;
  }
  .more-invite-btn {
    background: #c4c4c4;
    height: 20px;
    width: auto;
    min-width: 37px;
    border-radius: 30px;
    padding: 5px;
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
  }
  .email-inpt {
    label {
      font-weight: 700;
      font-size: 10px;
      line-height: 12.19px;
      text-transform: uppercase;
      color: #3a3a3a;
    }
    .input-wrap {
      position: relative;
      display: flex;
      align-items: center;
      background-color: #bbbbbb;
      padding: 6px;
      border-radius: 8px;
      .input-image {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 100%;
        box-shadow: 0px 10px 30px 0px #00000040;
        margin-left: 10px;
        img {
          width: 100%;
        }
      }
      .email-custom {
        width: 80%;
        overflow: hidden;
        font-size: 14px;
        line-height: 16px;
        font-weight: 700;
        margin-left: 10px;
      }
    }
  }
  .input-wrapper {
    p {
      font-size: 10px;
      line-height: 14.4px;
      font-weight: 700;
      color: #8f8f8f;
    }
  }
  .terms-condition-wrapper {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 10px;
    line-height: 14.2px;
  }
}
