.mobile-input-container {
  width: 100% !important;
}

.mobile-input-main {
  margin: 10px;
  border-radius: 5px !important;
  height: 40px !important;
  min-width: auto;
  padding: 16px 12px !important;
  border: none;
  outline: none;
  font-size: 12px !important;
  font-weight: 900 !important;
  font-family: 'Montserrat' !important;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
}

.margin-25p {
  margin-left: 25% !important;
}
.margin-20p {
  margin-left: 20% !important;
}

.mobile-input-btn {
  border-radius: 5px !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  height: 40px !important;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1) !important;
  border: 0px !important;
}

.mobile-input-dropdown {
  width: 230px !important;
  border-radius: 6px;
}
