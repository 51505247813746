//   Member Item
.member-item-wrapper {
    position: relative;
    padding: 60px 20px 20px 20px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    min-height: 160px;
    max-width: 370px;
    min-width: 370px;
    width: 100%;
    background-color: white;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    box-sizing: border-box;
    margin-bottom: 25px;
    &:hover {
        box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.15);
    }
    .setting-btn {
        position: absolute;
        top: 23px;
        right: 18px;
    }
    .member-item {
        position: relative;
        width: 100%;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        padding: 25px 15px 20px 15px;

        &-avatar {
            position: absolute;
            left: 16px;
            top: -40px;
            height: 56px !important;
            width: 56px !important;
            overflow: hidden;
            border-radius: 50%;
            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
        &-details {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-cont {
                h1 {
                    font-size: 12px;
                    text-transform: capitalize;
                    margin: 0;
                }
                span {
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 10px;
                    font-weight: 600;
                }
            }
            &-type {
                text-transform: uppercase;
                padding: 6px 5px;
                border-radius: 2.5px;
                display: inline-block;
                color: rgb(255, 255, 255);
                font-size: 10px;
                font-weight: 700;
            }
            &-leads {
                margin-top: 15px;
                display: flex;
                &-item {
                    h4 {
                        margin: 0;
                        font-size: 18px;
                        font-weight: 700;
                    }
                    span {
                        color: rgba(0, 0, 0, 0.5);
                        font-weight: 600;
                        font-size: 10px;
                    }
                }
                &-div {
                    height: 30px;
                    align-self: center;
                    width: 1px;
                    background-color: rgba(0, 0, 0, 0.08);
                    margin: 0 20px;
                }
            }
        }
    }
}

.member-item-fade {
    opacity: 0.5 !important;
}
