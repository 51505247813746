.blocker-container {
    width: 100%;
    padding-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upgrade-container {
    width: 602px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background: linear-gradient(270deg, #F3F3F3 0%, #FFFFFF 100.72%);
    height: 600px;
    position: relative;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: linear-gradient(270deg, rgba(243, 243, 243, 0.3) 0%, rgba(255, 255, 255, 0.3) 100.72%);
    backdrop-filter: blur(72px);

    & img {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -2;
        display: block;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
    }
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        background: linear-gradient(270deg, rgba(243, 243, 243, 0.3) 0%, rgba(255, 255, 255, 0.3) 100.72%);
        backdrop-filter: blur(72px);
        z-index: -1;
    }
    & .title {
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        text-align: center;
        max-width: 404px;
    }
    & .upgrade-btn {
        margin-top: 40px;
        width: 180px;
        height: 44px;
    }
    & .access-phone {
        margin-top: 16px;
        max-width: 320px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
        & a {
            color: rgba(0, 0, 0, 0.5);
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.feature-container {
    width: 602px;
    height: 600px;
    padding: 32px 36px 0px 36px;
    background: #ffffff;
    box-shadow: 0px 100px 200px rgba(0, 0, 0, 0.15);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    box-sizing: border-box;
    & .more-text {
        margin-top: 16px;
        font-weight: bold;
        font-size: 9px;
        line-height: 12px;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
    }
    & .slider {
        height: 218px;
        background: rgba(142, 142, 142, 0.08);
        border: 1px solid rgba(255, 255, 255, 0.16);
        box-sizing: border-box;
        backdrop-filter: blur(50px);
        border-radius: 5px;
        width: 530px;
        box-sizing: border-box;
        padding: 24px 18px 24px 32px;

        & .plan-name {
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 65px;
        }

        & .rc-slider {
            width: 464px;
            height: 8px;
            box-sizing: border-box;
            & .rc-slider-rail {
                height: 8px;
            }
            & .rc-slider-track {
                height: 8px;
            }
            & .rc-slider-step {
                height: 8px;
            }
        }

        & .plan-info {
            margin-top: 20px;
            & .price {
                font-weight: bold;
                font-size: 24px;
                line-height: 28px;
                color: #000000;
                & span {
                    font-size: 14px;
                    line-height: 16px;
                    color: #000000;
                }
            }

            & .visitors {
                margin-top: 2px;
                color: rgba(0, 0, 0, 0.5);
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                & span {
                    color: #4425f5;
                }
            }
        }
    }

    & .features {
        margin-top: 24px;
        & .title {
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: #000000;
        }

        & .feature-box {
            display: flex;
            margin-top: 16px;
            column-gap: 20px;

            & .container {
                & .row {
                    display: flex;
                    & .feature {
                        background: rgba(255, 255, 255, 0.08);
                        border: 1px solid rgba(255, 255, 255, 0.16);
                        box-sizing: border-box;
                        box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.24);
                        backdrop-filter: blur(50px);
                        border-radius: 5px;
                        height: 108px;
                        width: 116px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-direction: column;
                        & .feature-name {
                            font-weight: bold;
                            font-size: 11px;
                            line-height: 13px;
                            text-align: center;
                            color: rgba(0, 0, 0, 0.64);
                        }
                        &.api-access {
                            margin-right: 20px;
                            margin-bottom: 20px;
                            & img {
                                width: 42px;
                                height: 35px;
                                margin-bottom: 20px;
                            }
                        }
                        &.dedicated-support {
                            & img {
                                width: 30px;
                                height: 32px;
                            }
                            & .feature-name {
                                margin-top: 16px;
                                max-width: 65px;
                            }
                        }
                        &.teams {
                            width: 252px;
                            height: 112px;
                            & img {
                                width: 112px;
                                height: 40px;
                                filter: drop-shadow(0px 5.86548px 17.5964px rgba(0, 0, 0, 0.25));
                            }
                            & .feature-name {
                                margin-top: 20px;
                            }
                        }

                        &.embed {
                            width: 119px;
                            height: 141px;
                            & img {
                                width: 92px;
                                height: 74px;
                            }
                            & .feature-name {
                                max-width: 95px;
                                margin-top: 15px;
                            }
                        }

                        &.dashboard {
                            width: 119px;
                            height: 141px;
                            margin-left: 20px;
                            margin-bottom: 20px;
                            & img {
                                width: 95px;
                                height: 78px;
                                margin-top: 16px;
                            }
                            & .feature-name {
                                max-width: 95px;
                            }
                        }
                        &.custom-link {
                            width: 258px;
                            height: 79px;
                            & img {
                                width: 220px;
                                height: 24px;
                                margin-bottom: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
.sign-out-handle {
    margin-top: 90px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.88);
    & img {
        width: 16px;
        height: 16px;
        margin-right: 7px;
    }
}
