.detail-container {
    // position: absolute;
    // top: 0px;
    // left: 0px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 4px !important;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 10px;
        background-color: #adadad;
    }
    &::-webkit-scrollbar-track {
        background: #dcdcdc !important;
        width: 4px !important;
    }

    .profile {
        width: 100%;
        padding: 50px 176px;
        padding-top: 160px;
        padding-bottom: 10px;
        box-sizing: border-box;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 25px;
        margin-top: 34px;
        .pbox {
            width: 100%;
            border-radius: 12px;
            padding: 24px 28px;
            background: rgba(255, 255, 255, 0.8);
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
            backdrop-filter: blur(56px);
            border-radius: 12px;
            h1 {
                margin: 0 0 12px 0;
                font-size: 10px;
                font-style: normal;
                font-weight: 700;

                line-height: 12px;
                text-transform: uppercase;
            }
            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                h4 {
                    font-size: 16px;
                    font-weight: bold;
                    display: inline-block;
                    margin-top: 0;
                }
                h6 {
                    margin: 0;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 12px;
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    color: rgba(0, 0, 0, 0.56);
                    cursor: pointer;
                    align-self: flex-start;
                }
            }
        }
        &__info {
            font-family: 'Montserrat';
            color: #000000;
            position: relative;

            hr {
                border: 0.5px solid rgba(0, 0, 0, 0.08);
                margin: 17px 0 20px 0;
            }
            .profile-image {
                height: 140px;
                width: 140px;
                position: absolute;
                top: -40px;
                left: 50%;
                transform: translate(-50%, -50%);
                border-radius: 50%;
                overflow: hidden;
                box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.1);
                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            &--email {
                display: flex;
                align-items: center;
                margin-bottom: 8px;
                img {
                    margin-right: 10px;
                }
                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.5);
                    margin: 0;
                }
            }
            .add-ctas {
                margin-top: 20px;
                display: flex;
                flex-direction: column;
                row-gap: 15px;
            }

            &--lastactive {
                display: flex;
                align-items: center;
                img {
                    margin-right: 10px;
                }
                .lastactive-day {
                    font-style: normal;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.5);
                    margin: 0;
                    text-transform: uppercase;
                    color: rgba(0, 0, 0, 0.24);
                    font-size: 10px;
                }
            }
        }
        &__ctas {
            .contacts-list {
                display: flex;
                padding: 8px;
                background-color: #fff;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                border-radius: 25px;
                width: fit-content;

                .cta-btn {
                    position: relative;
                    display: flex;
                    img {
                        height: 24px;
                        width: 24px;
                        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
                        border-radius: 50%;
                    }
                    & .tooltip {
                        position: absolute;
                        top: -40px;
                        left: 50%;
                        transform: translateX(-50%);
                        padding: 10px 12px;
                        background: #ffffff;
                        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                        border-radius: 4px;
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 12px;
                        color: #000000;
                        display: none;
                        border-radius: 25px;
                        &::before {
                            content: '';
                            position: absolute;
                            width: 0;
                            height: 0;
                            top: 32px;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-top: 5px solid #fff;
                        }
                    }
                    &:hover {
                        & .tooltip {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
                .cta-btn:not(:last-child) {
                    margin-right: 8px;
                }
            }
        }
        &__role {
            margin-bottom: 80px;
            .headquarter {
                display: flex;
                align-items: flex-start;
                column-gap: 10px;
                & img {
                }
                .details {
                    display: flex;
                    flex-direction: column;
                    row-gap: 4px;
                    h1 {
                        margin: 0px;
                    }
                    p {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        color: rgba(0, 0, 0, 0.5);
                        margin: 0px;
                    }
                }
            }
            .permissions {
                display: flex;
                column-gap: 30px;
                .member {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 12px;
                    padding: 4px;
                    width: fit-content;
                    text-align: center;
                    text-transform: uppercase;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.5);
                    background: #ffffff;
                    border-radius: 2.26573px;

                    &.not-member {
                        background: #000000 !important;
                        color: #fff !important;
                    }

                    &.owner {
                        background: #4425f5 !important;
                        color: #fff !important;
                    }
                }
                & > .ht-container:first-child > .member {
                    background: rgba(0, 0, 0, 0.3);
                    color: #fff;
                }
            }
        }
    }
}

.shimmer-effect {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.072) 0%, rgba(0, 0, 0, 0) 72.27%), rgba(0, 0, 0, 0.04);
    animation-duration: 15s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    border-radius: 4px;
}
.shimmer-effect.name {
    width: 80px;
    height: 12px;
    margin-bottom: 30px;
}

.shimmer-effect.mail {
    width: 80px;
    height: 12px;
}
.shimmer-effect.last-active {
    width: 134px;
    height: 16px;
}
.shimmer-effect.people {
    width: 200px;
    height: 16px;
}
.shimmer-effect.ctas {
    width: 220px;
    height: 40px;
}
.shimmer-effect.ctas.mail {
    margin-top: 10px;
}
.shimmer-effect.role {
    width: 56px;
    height: 20px;
}
.shimmer-effect.permission {
    width: 468px;
    height: 16px;
    margin-top: 6px;
}
@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}

.button-resend {
    width: 241px !important;
    background-color: black;
    padding: 15px 20px;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;

    &:hover {
        box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
    }
}

.sign-out-btn-profile {
    width: 100%;
    bottom: 0px;
    position: fixed;
    height: 66px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    padding-top: 25px;
    box-sizing: border-box;
    backdrop-filter: blur(17.5px);
}
.bottom-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 112px !important;
    background-color: #ffffff52;
    width: 100%;
    z-index: 100;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;
    a {
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
        color: #e00000;
        cursor: pointer;
    }
    button {
        background-color: black;
        border: none;
        color: white;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        height: 48px !important;
        // width: 148px !important;
        padding: 16px 32px !important;
        margin: 0px !important;
        border-radius: 6px;
        text-transform: uppercase;
        &:disabled {
            background-color: #bfbfbf;
        }
        div {
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }
}
.revoke-invitation {
    color: #e00000;
}
