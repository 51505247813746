//alert flags container
.alertBox {
    width: 500px;
    height: 42px;
}
//hiding alertcontainer in settings page
.hideAlert .alert-container {
    display: none;
}
.settings-mega-container {
    border-top: 0.5px solid rgba(0, 0, 0, 0.15);
    padding-top: 30px;
    margin-bottom: 100px;
}
// code formatter styling
pre {
    overflow: auto;
    padding: 1.2em 1em;
    background: rgba(0, 0, 0, 0.72);
    border-radius: 6px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05) !important;
}

pre code,
pre .line-number {
    font: normal normal 12px/14px 'Courier New', Courier, Monospace;
    color: rgba(183, 183, 183, 1);
    display: block;
    line-height: 188%;
    font-weight: 700;
    height: 220px;
    margin-left: 30px;
}

pre .line-number {
    float: left;
    margin: 0 1em 0 -1em;
    color: rgba(183, 183, 183, 1);
    text-align: right;
}

pre .line-number span {
    display: block;
    padding: 0 20px;
    font-weight: 700;
    color: rgba(183, 183, 183, 1);
    border-right: 1px solid #eee;
}

pre .cl {
    display: block;
    clear: both;
}
.code {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
    padding: 12px;
}
.cc-btn {
    right: 20px;
    bottom: 20px;
    border-radius: 6px;
}

.disable-settings {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.6);
    cursor: not-allowed;
}

// Widget Wrapper
.widget-wrapper {
    width: 278px;
    min-width: 278px;
    position: relative;
    border-radius: 18px;
    background-color: white;
    box-shadow: 0px 7.909887790679932px 15.819775581359863px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
    h1 {
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        margin: 0;
        padding: 0;
    }
    .widget-image {
        width: 90px;
        height: 132px;
        background-color: #000000;
        border-radius: 12px;
        box-shadow: 0px 20px 35px 0px rgba(0, 0, 0, 0.15);
        overflow: hidden;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .loader {
            position: absolute;
            top: 50%;
            left: 15%;
        }
    }
    .position-wrapper {
        h1 {
            font-weight: 700;
            font-size: 9px;
            line-height: 11px;
            color: rgba(58, 56, 245, 1);
        }
        .toggle-wrapper {
            width: 145px;
            height: 30px;
            background-color: #fff;
            padding: 3px;
            border-radius: 6px;
            box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
            .toggle {
                font-size: 9px;
                font-weight: 700;
                line-height: 11px;
                width: 50%;
                height: 100%;
                text-align: center;
                border-radius: 6px;
                transition: all 0.2s ease-in-out;
                color: rgba(0, 0, 0, 0.5);
            }
            .active {
                color: #fff;
                background-color: #000;
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.15);
            }
        }
        .pos-input-wrapper {
            .pos-wrapper {
                img {
                    height: 7px;
                }
                .inpt-container {
                    position: relative;
                    span {
                        position: absolute;
                        right: 15px;
                        font-size: 12px;
                        top: 47%;
                    }
                }
                .inpt {
                    max-width: 74px;
                    height: 34px;
                    border: 1px solid rgba(215, 215, 215, 1);
                    border-radius: 6px;
                }
                input[type='number']::-webkit-inner-spin-button,
                input[type='number']::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    margin: 0;
                }
            }
        }
    }
}

// Logo wrapper
.logo-wrapper {
    max-height: 153px;
    width: 278px;
    min-width: 278px;
    border-radius: 18px;
    background-color: white;
    box-shadow: 0px 7.909887790679932px 15.819775581359863px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;

    h1 {
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
        margin: 0;
        padding: 0;
    }
    .droplogo-wrapper {
        height: 90px;
        width: 90px;
        background-color: #000000;
        border-radius: 12px;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
    }
    .details-wrapper {
        .detail {
            h1 {
                font-weight: 700;
                font-size: 9px;
                line-height: 11px;
                color: rgba(58, 56, 245, 1);
                margin: 0;
                padding: 0;
            }
            span {
                font-size: 12px;
                font-weight: 700;
                margin: 0;
                padding: 0;
            }
        }
    }
}

.tvproperties-wrapper {
    position: relative;
    h1 {
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
    }
    .properties-wrapper {
        .property {
            h1 {
                font-weight: 700;
                font-size: 12px;
                line-height: 14px;
                margin: 0;
                padding: 0;
            }
        }
        h2 {
            font-size: 10px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.5);
        }
    }
}
.portfolio-visibility-wrapper {
    position: relative;
    max-height: 153px;
    width: 278px;
    min-width: 278px;
    border-radius: 18px;
    background-color: white;
    box-shadow: 0px 7.909887790679932px 15.819775581359863px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
    h1 {
        font-size: 14px;
        font-weight: 700;
        line-height: 14px;
    }
    .heading {
        font-size: 12px;
        font-weight: 700;
        line-height: 14px;
        color: rgba(139, 139, 139, 1);
    }
}

.tutorial-card {
    height: 400px;
    max-width: 206px;
    width: 100%;
    background-color: #000000;
    background-size: cover;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    -webkit-box-shadow: 10px 10px 115px -65px rgba(0, 0, 0, 0.47);
    -moz-box-shadow: 10px 10px 115px -65px rgba(0, 0, 0, 0.47);
    box-shadow: 10px 10px 115px -65px rgba(0, 0, 0, 0.47);
    // margin-left: 30px;
    // :first-child {
    //   margin-left: 0;
    // }
    .tutorial-image {
        position: absolute;
        height: 400px;
        width: 206px;
        border-radius: 12px;
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background-color: rgba($color: #767676, $alpha: 0.5);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border-radius: 6px;
        border: 1px solid rgba(255, 255, 255, 0.18);
        font-size: 10px;
        font-weight: 700;
        color: white;
        transition: all 0.1s ease-in-out;
        -webkit-box-shadow: 0px 12px 24px rgba($color: #767676, $alpha: 0.4);
        -moz-box-shadow: 0px 12px 24px rgba($color: #767676, $alpha: 0.4);
        box-shadow: 0px 12px 24px rgba($color: #767676, $alpha: 0.4);
        &:hover {
            -webkit-box-shadow: 0px 12px 24px rgba($color: #767676, $alpha: 0.6);
            -moz-box-shadow: 0px 12px 24px rgba($color: #767676, $alpha: 0.6);
            box-shadow: 0px 12px 24px rgba($color: #767676, $alpha: 0.6);
        }
    }
}

.views-wrapper {
    margin: 0;
    font-size: 10px;
    line-height: 10px;
    font-weight: 600;
    color: #909090;
    display: flex;
    align-items: center;
    margin-top: 4px;
    img {
        width: 15px;
        margin-right: 5.25px;
    }
}

.views-heading {
    color: #000 !important;
    font-size: 12px !important;
    font-weight: 700 !important;
}

.customise-wrapper {
    h1 {
        font-weight: 700;
        font-size: 9px;
        line-height: 11px;
        color: rgba(58, 56, 245, 1);
    }
    .widget-size-container div:nth-child(1) div.widget-size-img-container {
        width: 24px;
        height: 30px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        overflow: hidden;
        margin: 10px 0;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .widget-size-container div:nth-child(2) div.widget-size-img-container {
        height: 56px;
        width: 33px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        overflow: hidden;
        margin: 10px 0;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .widget-size-container div:nth-child(3) div.widget-size-img-container {
        height: 84px;
        width: 45px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        overflow: hidden;
        margin: 10px 0;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .widget-dimension {
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.5);
        padding: 0;
        margin: 0;
    }
    .widget-size-h {
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.5);
    }
    .widget-custom-checkbox {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 2px solid #c0c0c0;
        background-color: #fff;
        margin-bottom: 10px;
        cursor: pointer;
        padding: 2px;
        div {
            border-radius: 50%;
            height: 100%;
            width: 100%;
            background-color: #001aff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
