// Color
.c-gray {
    color: #8f8f8f;
}
.c-lgray {
    color: #c9c9c9;
}
.c-dgray {
    color: #adadad;
}
.c-blue {
    color: #0821fe;
}
.c-skyblue {
    color: #34aaff;
}
.c-dblue {
    color: #0047ff;
}
.c-black {
    color: #000000;
}
.c-lblack {
    color: #4d4d4d;
}
.c-white {
    color: #ffffff;
}
.c-red {
    color: #f6655a;
}
.c-error {
    color: #ff0000;
}
.c-grey {
    color: #3a3a4a;
}
.c-sub {
    color: #595959;
}
.c-sub2 {
    color: #00000080;
}
// Backgound Color
.bg-black {
    background-color: #000000;
}
.bg-black-50 {
    background-color: rgba(0, 0, 0, 0.5);
}
.bg-red {
    background-color: #ff0000;
}
.bg-blue {
    background-color: #0821fe;
}
.bg-skyblue {
    background-color: #34aaff;
}
.bg-dblue {
    background-color: #0047ff;
}
.bg-white {
    background-color: #ffffff;
}
.bg-lightgray {
    background-color: #9f9f9f;
}
.bg-lgray {
    background-color: #c9c9c9 !important;
}
.bg-sgray {
    background-color: #f3f3f3;
}
.bg-green {
    background-color: #00bf4d;
}
.bg-alert-yellow {
    background-color: #ff9e0c;
}
.bg-alert-green {
    background-color: #00e09d;
}

.bg-error {
    background-color: #f6655a;
}
// Font Size
.fs-6 {
    font-size: 6px;
}
.fs-7 {
    font-size: 7px;
}
.fs-8 {
    font-size: 8px;
}
.fs-9 {
    font-size: 9px;
}
.fs-10 {
    font-size: 10px;
}
.fs-11 {
    font-size: 11px;
}
.fs-12 {
    font-size: 12px;
}
.fs-14 {
    font-size: 14px;
}
.fs-16 {
    font-size: 16px;
}
.fs-17 {
    font-size: 17px;
}
.fs-18 {
    font-size: 18px;
}
.fs-20 {
    font-size: 20px;
}
.fs-24 {
    font-size: 24px;
}
.fs-32 {
    font-size: 32px;
}
.fs-36 {
    font-size: 36px;
}
.fs-40 {
    font-size: 40px;
}
.fs-60 {
    font-size: 60px;
}
.fs-65 {
    font-size: 65px;
}
.fs-100 {
    font-size: 100px;
}

// Font Weight
.fw-b {
    font-weight: bold;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}
.fw-800 {
    font-weight: 800;
}
.fw-900 {
    font-weight: 900;
}

// Border
.br-2 {
    border-radius: 2px;
}
.br-3 {
    border-radius: 3px;
}
.br-4 {
    border-radius: 4px;
}
.br-5 {
    border-radius: 5px;
}
.br-8 {
    border-radius: 8px;
}
.br-10 {
    border-radius: 10px;
}
.br-12 {
    border-radius: 12px;
}
.br-18 {
    border-radius: 18px;
}
.br-20 {
    border-radius: 20px;
}
.br-40 {
    border-radius: 40px;
}
.br-100 {
    border-radius: 100px;
}
.b-none {
    border: none;
}
.b-error {
    border: 1px solid #ff0000;
}
.br-2-white {
    border-right: 2px solid #ffffff;
}
.b-white {
    border: 1px solid #ffffff;
}
.b-gray {
    border: 1px solid #8f8f8f;
}

// Opacity
.o-50 {
    opacity: 0.5;
}
.o-00 {
    opacity: 0;
}

// Cursor
.cur-pointer {
    cursor: pointer;
}
.cur-disabled {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
}

// Cases
.uc {
    text-transform: uppercase;
}

.no-underline {
    text-decoration: none;
}

// Box Shadow
.b-shadow {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

// Line Height
.lh-12 {
    line-height: 12px;
}
.lh-16 {
    line-height: 16px;
}

// Z Index
.zi-1 {
    z-index: 1;
}
.zi--1 {
    z-index: -1;
}
.zi-50 {
    z-index: 50;
}
.zi-100 {
    z-index: 100;
}
