.selected-text {
    appearance: none;
    padding: 14px 16px;
    border: none;
    border-radius: 6px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    outline: none;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    border: 1px solid #e6e6e6;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    &.no-avatar {
        padding: 10px 12px;
    }
    &.active.no-avatar {
        padding: 10px 12px;
    }
    & .template-arrow {
        position: absolute;
        top: 35px !important;
        right: 16px;
    }
}

.select-options {
    position: absolute;
    z-index: 9;
    margin: 0;
    padding: 0;
    text-align: center;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    // padding-left: 12px;
    width: 100%;
    max-height: 207px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 4px !important;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 10px;
        background-color: #adadad;
    }
}

.custom-select-option {
    cursor: pointer;
    height: 40px;
    color: #000;
    padding: 14px 16px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    box-sizing: border-box;
    text-align: left;
    & .avatar-wrapper {
        width: 24px;
        height: 24px;
        & img {
            left: 16px !important;
        }
    }
    & .link-icon {
        width: 16px;
        height: 16px;
        object-fit: cover;
    }
    & .checkmark {
        width: 14px;
        height: 9px;
        position: absolute;
        top: -22px;
        right: 25px;
        padding: 0px !important;
        display: none;
        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.custom-select-option:hover {
    background: rgba(0, 0, 0, 0.08);
    & .checkmark {
        display: block;
    }
}

.custom-select-container {
    &.assignee {
        & .selected-text {
            padding: 10px 12px;
            & .assigne__name {
                display: block;
                margin-left: 8px;
                text-transform: capitalize;
            }

            & .cancel-dropdown {
                font-weight: bold;
                font-size: 10px;
                line-height: 12px;
                color: rgba(0, 0, 0, 0.5);
                text-transform: uppercase;
                text-decoration: underline;
                position: absolute;
                top: 34px !important;
                right: 16px;
            }
        }
        & img {
            top: 38px !important;
        }
    }
}

.select-options {
    & .assign-self {
        text-transform: uppercase;
        text-decoration: underline;
        color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        padding: 14px 16px;
        font-size: 10px;
        line-height: 12px;
        font-weight: 700;
        box-sizing: border-box;
        text-align: left;
    }
    & .search-assigne {
        margin-bottom: 12px;
        padding-top: 0px;
        padding-bottom: 0px;
        position: relative;
        cursor: pointer;
        color: #000;
        padding: 0px 16px;
        font-size: 12px;
        line-height: 12px;
        font-weight: 700;
        box-sizing: border-box;
        text-align: left;
        & .search-container {
            border-bottom: 1px solid rgba(0, 0, 0, 0.06);
            padding-bottom: 0px;
            position: relative;
            padding-top: 0px;
        }
        & img {
            position: absolute;
            top: 50% !important;
            left: 0px;
            transform: translateY(-50%);
            opacity: 0.5;
            width: 16px;
            height: 16px;
            margin-right: 10px;
        }
        & input {
            box-shadow: none;
            margin: 0;
            font-weight: 600;
            font-size: 12px;
            line-height: 130%;
            color: #000000;
            font-weight: 600;
            font-size: 12px;
            line-height: 130%;
            padding-left: 25px;
        }
    }
}

.custom-select-option {
    position: relative;
    display: flex;
    align-items: center;
    &.loading-li {
        padding-top: 0px;
        padding-bottom: 0px;
        display: flex;
        column-gap: 8px;
        align-items: center;
    }
    & .avatar-loading {
        width: 28px;
        height: 28px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 72.27%), #ededed;
        opacity: 0.32;
        border-radius: 100px;
        padding: 0px;
        box-sizing: border-box;
    }
    & .name-loading {
        flex-grow: 1;
        height: 14px;
        padding: 0px;
        box-sizing: border-box;
        background: #ddd;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 72.27%), #ededed;
        opacity: 0.32;
        border-radius: 100px;
        background-size: 668px 20px;
        animation-duration: 4.2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shimmer;
        animation-timing-function: linear;
    }

    & img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        left: 10px !important;
    }
    & .assigne__name {
        position: relative;
        left: 12px;
        margin-left: 0px !important;
    }
}

// loading styles
.shimmer-bg {
    width: 668px;
    height: 20px;
    animation-duration: 4.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 72.27%), #ededed;
    background-size: 668px 20px;
}

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}

.avatar-div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 8px;
    & img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        left: 10px !important;
        top: 38px !important;
    }
    & .assigne__name {
        position: relative;
        left: 24px;
        margin-left: 0px !important;
    }
}

.add-template {
    border-top: 1.5px solid rgba(0, 0, 0, 0.08);
    border-radius: 1px;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background: none !important;
    }
}
