.current-screen-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    pointer-events: none;
    top: 60px;
}

.edit-profile-container {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 4px !important;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 10px;
        background-color: #adadad;
    }
    &::-webkit-scrollbar-track {
        background: #dcdcdc !important;
        width: 4px !important;
    }
}

.edit-profile {
    width: 100%;
    padding: 120px 176px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 25px;

    &__dept {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
    &__teamRole {
        width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 12px;
    }
    &__permission {
        width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 45px;
        row-gap: 12px;
    }
    h4 {
        font-weight: 700;
        font-size: 14px;
        margin: 0px;
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
    }
    .edit-profile-pic-upload {
        position: relative;
        height: 140px;
        width: 140px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: grey;
        cursor: pointer;
        overflow: hidden;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
        .upload {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 56px;
            width: 56px;
            object-fit: contain;
        }
    }
    hr {
        border: 0.5px solid rgba(0, 0, 0, 0.08);
        width: 100%;
        margin-top: 30px;
    }
    & .agent-field {
        width: 100%;

        &:first-child {
            margin-top: 0px;
        }
        &:last-child {
            margin-bottom: 20px;
        }
        & .optional-field {
            display: flex;
            align-items: center;
            width: 100%;
            .title {
                margin-left: 15px;
                font-size: 10px;
                font-weight: 700;
                line-height: 12px;
                cursor: pointer;
            }
        }
        & .optional-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 100%;
            background-color: rgba($color: #000000, $alpha: 0.2);
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: scale(0.9);
            }
            img {
                width: 8px;
            }
        }

        & .input-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: -10px;
            .flat-inpt-wrapper {
                display: flex;
                position: relative;

                & label {
                    font-size: 10px;
                    line-height: 12px;
                }
                input {
                    min-width: 252px;
                    width: 252px;
                    padding-right: 45px !important;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                & .mobile-input-btn.flag-dropdown {
                    box-sizing: border-box;
                    width: 64px;
                    height: 44px !important;
                }
                & .mobile-input-main {
                    margin-left: 70px !important;
                    height: 44px !important;
                }
                & .msg-text-inp {
                    width: 504px;
                    height: 94px;
                    margin-bottom: 5px;
                }
                & textarea {
                    max-width: 100%;
                    resize: none;
                    padding: 14px 16px;
                    border-radius: 5px;
                    font-size: 12px !important;
                    font-weight: 900;
                    border: none;
                    box-sizing: border-box;
                    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                    outline: none;
                }
                & .character-count {
                    position: absolute;
                    right: 0px;
                    bottom: -15px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: rgba(0, 0, 0, 0.24);
                }
            }
        }
    }
}
.containerselect {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 16px;
    box-sizing: border-box;
    position: relative;
    cursor: pointer;
    &.blocked-select {
        background: #bfbfbf;
        color: rgba(0, 0, 0, 0.5) !important;
    }
    &.dept-container {
        width: 123px;
        height: 44px;
    }
    &.team-container {
        width: 206px;
        height: 44px;
    }
    &.perm-container {
        width: 123px;
        height: 44px;
    }
}
.label-style {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 6px;
}
