.team-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 200px;
}
.active-teams {
    min-height: 660px;
    position: relative;
}

.loading-teams {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 380px;
    img {
        height: 100%;
        width: 220px;
    }
    span {
        margin-top: 20px;
        text-align: center;
    }
}

.button-drop-link {
    position: relative;
    background-color: transparent;
    padding: 7px 10px;
    color: rgba(0, 0, 0, 0.56);
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        margin-left: 5px;
    }
}

// new styles
.teams-mega-container {
    height: 600px;
    // padding-top: 30px;
    display: flex;

    background: #ffffff;
    box-shadow: 0px 100px 200px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    & .img-container {
        width: 50%;
        border-radius: 12px;
        height: 100%;

        & img {
            width: 100%;
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            height: 100%;
            object-fit: cover;
        }
    }
    & .setup-container {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 7px;
        & h1 {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color: #000000;
            max-width: 290px;
            margin: 0px;
        }
        & p {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: rgba(0, 0, 0, 0.5);
            max-width: 395px;
            margin: 0px;
        }
        & .setup-btn {
            width: 258px;
            height: 48px;
            text-transform: uppercase;
        }
    }
}
