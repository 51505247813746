.top-header {
    display: flex;
    column-gap: 355px;
    align-items: center;
    justify-content: flex-start;
    justify-content: space-between;
    z-index: 2;
    margin-top: 24px;
    margin-left: 24px;
    box-sizing: border-box;
    position: absolute;
    top: 0px;
    left: 0px;
    pointer-events: none;
    & .back-button {
        height: 32px;
        width: 32px;
        cursor: pointer;
        z-index: 100;
        border-radius: 100%;
        box-shadow: 0px 5px 10px 0px rgba($color: #000000, $alpha: 0.2);
        pointer-events: auto;

        img {
            height: 32px;
            width: 32px;
            cursor: pointer;
        }
    }

    & .step-indicator {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        justify-self: center;
        z-index: 2;
        .circle {
            height: 6px;
            width: 6px;
            background-color: black;
            opacity: 0.12;
            border-radius: 100%;
            margin: 0px 5px;
            transition: all 0.2s ease-in-out;
            &.c-white {
                background-color: #ffffff;
                &.active {
                    background-color: #ffffff !important;
                }
            }
        }
        .active {
            opacity: 1;
            background-color: black;
        }
    }
}

.current-step-title {
    margin-top: 72px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    pointer-events: none;
    &.ft-white {
        color: #fff;
    }
    .total-videos-title {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 38px;
        width: 67px;
        height: 20px;
        max-width: 67px;
        max-height: 20px;
        font-size: 10px;
        line-height: 11px;
        color: #fff;
        top: 52px;
        left: 50% !important;
        transform: translateX(-50%) !important;
        background-color: #6b6b6bb8;
    }
}
