.video-container-item {
    position: relative;
    background-color: transparent;
    & .bg-image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        position: absolute;
        object-fit: cover;
    }
    & .video-container-item-desc {
        & input {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px;
        }
        & input::placeholder {
            color: #0000003d;
        }
    }
    & .video-container-item-download {
        width: 20px;
        height: 20px;
        background: rgba(0, 0, 0, 0.56);
        box-shadow: 0px 5.84524px 11.6905px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        position: absolute;
        right: 10px;
        bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        & img {
            width: 12px;
            height: 9px;
        }
        .loader-animation {
            width: 20px !important;
            height: 20px !important;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 15px;
                width: 15px;
            }
        }
    }
}

.space-custom-checkbox {
    z-index: 2;
    position: absolute;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid #c0c0c0;
    background-color: #fff;
    cursor: pointer;
    padding: 2px;
    top: 8px;
    right: 8px;
    div {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        background-color: #001aff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.aspect-ratio-btn {
    position: absolute;
    height: 17px;
    width: 19px;
    top: 10px;
    left: 10px;
    border: 1px solid rgba(255, 255, 255, 0.27);
    border-radius: 3px !important;
    color: #fff;
    font-weight: 700;
    font-size: 8px;
    z-index: 99;
    padding: 3px 0 3px 1px;
}
