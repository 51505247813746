.template-save-wrapper {
    padding: 40px 40px 32px 40px;
    width: 316px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(70px);
    border-radius: 8px;
    & h1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
        margin: 0px;
    }
    & p {
        margin-top: 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.5);
    }

    & .remove-btn-wrapper {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        & button {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            color: rgba(0, 0, 0, 0.72);
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
        }
        & .active {
            background: #000000;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
            border-radius: 6px;
            color: #fff;
            height: 48px;
        }
        & .not-active {
            margin-top: 28px;
            &.delete {
                color: rgba(255, 0, 0, 0.72);
            }
        }
    }
}
