@mixin center-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
ul {
    margin: 0;
    padding: 0;
}
.pr-video-list {
    max-height: 360px;
    overflow-y: scroll;
    padding: 40px;
    margin: -40px;
    padding-bottom: 50px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
}
.pr-video {
    background: #ffffff;
    box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(56px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 12px;
    padding: 20px 16px;
    padding-right: 18px;
    display: flex;
    align-items: center;
    font-weight: 700;
    margin-bottom: 8px;
    position: relative;
    cursor: pointer;
    &:last-child {
        margin-bottom: 0px;
    }
    &:first-child {
        margin-top: 16px;
    }
    &__banner {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @include center-content;
        margin-right: 12px;
        overflow: hidden;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        & img {
            object-fit: cover;
            width: 100%;
            // border-radius: 50%;
            height: 100%;
        }
    }

    &__details {
        width: 160px;
        font-size: 12px;
        line-height: 14px;
        color: #000000;
        display: flex;
        flex-direction: column;
        row-gap: 5px;
        margin-right: 23px;
    }
    &__name {
        text-transform: capitalize;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__views {
        @include center-content;
        justify-content: flex-start;
        column-gap: 5px;
        & img {
            height: 25px;
            width: 25px;
        }
    }
    &__sort-value {
        // width: 43px;
        font-size: 16px;
        line-height: 16px;
        & .perc {
            font-size: 12px;
            line-height: 14px;
        }
    }
    &__more {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-42%);
        right: 23px;
        & img {
            width: 15px;
        }
    }
}

// Loading
.skills-loading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .skills-header {
        display: flex;
    }
    .skills-loading-circle {
        height: 40px;
        width: 40px;
        border-radius: 100%;
    }
    .skills-loading-rect-wrapper {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        .skills-loading-rect-one {
            width: 102px;
            height: 12px;
            border-radius: 4px;
        }
        .skills-loading-rect-two {
            margin-top: 6px;
            width: 48px;
            height: 20px;
            border-radius: 4px;
        }
    }
    .skills-loading-rect-three {
        width: 48px;
        height: 20px;
        border-radius: 6px;
    }
}
.no-skills {
    margin-top: 68px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & .skill {
        width: 48px;
        height: 48px;
        background: #000000;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
            width: 20px;
            height: 20px;
            object-fit: cover;
        }
    }
    & .text-content {
        margin-top: 24px;
        & h2 {
            margin: 0px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            color: #000000;
        }
        & p {
            margin: 8px 0px 0px 0px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            color: rgba(0, 0, 0, 0.5);
            max-width: 285px;
        }
    }
}
