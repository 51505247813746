.general-settings-container {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 20px;
    h4.general-heading {
        font-weight: bold;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 30px;
    }
    &-wrapper {
        background: #ececec;
        border-radius: 6px;
        padding: 30px 25px;

        h4.general-sub-heading {
            font-weight: bold;
            font-size: 10px;
            text-transform: uppercase;
            margin-top: 0;
            color: rgba(0, 0, 0, 0.5);
        }
        &-heading {
            width: 65%;
            h4 {
                margin-top: 10px;
                font-weight: 600;
                font-size: 14px;
                color: #000000;
                margin-bottom: 0;
            }
            p {
                font-weight: 600;
                font-size: 10px;
                color: rgba(0, 0, 0, 0.5);
                margin-top: 5px;
                margin-bottom: 5px;
            }
            &-toggle {
                box-sizing: border-box;
                min-width: 220px;
                height: 34px;
                padding: 1px;
                background: #ffffff;
                box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                display: flex;
                align-items: center;
                &-sub {
                    width: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 4px;
                    height: 100%;
                    cursor: pointer;
                }
                .active {
                    background-color: #000;
                    transition: background-color 200ms ease;
                }
            }
        }
        hr {
            border: 0.5px solid rgba(0, 0, 0, 0.08);
        }
    }
}
