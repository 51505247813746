.widget-settings-accordion {
    transition: all 0.2s ease-in-out;
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(56px);
    border-radius: 12px;
    margin-bottom: 20px;

    &-container {
        cursor: pointer;
        padding: 20px 0 20px 28px;
        h4 {
            font-weight: 700;
            font-size: 14px;
            color: #000;
            margin: 0;
            span:nth-child(1) {
                height: 18px;
                width: 18px;
                border-radius: 50%;
                border: 2px solid #000;
                display: inline-block;
                margin-right: 15px;
                text-align: center;
                line-height: 18px;
            }
            span.beta {
                color: #fff;
                font-weight: 700;
                font-size: 10px;
                background-color: #4425f5;
                padding: 5px 6px;
                border-radius: 4px;
                margin-left: 10px;
                text-transform: uppercase;
            }
        }
        img {
            top: 50%;
            right: 20px;
            transform: translate(-50%, -50%);
        }
        &-website {
            padding: 10px 20px 20px 20px;
            &-info {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                background: #f3f3f3;
                padding: 12px;
                border-radius: 6px;
                img {
                    margin-right: 12px;
                }
                h4 {
                    margin: 0;
                    font-weight: 600;
                    font-size: 12px;
                    color: #00000080;
                }
            }
            &-snippet {
                pre {
                    margin: 10px 0 0 0;
                }
            }
        }
        &-context {
            padding: 10px 20px 20px 20px;

            &-content {
                background: #ececec;
                border-radius: 6px;
                padding: 0px 0px 0 20px;
                overflow: hidden;
                &-heading {
                    display: flex;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
                    align-items: center;
                    justify-content: space-between;
                    height: 32px;
                    padding-bottom: 10px;
                    margin-top: 10px;
                    h4 {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 700;
                    }
                    .toggler {
                        display: flex;
                        align-items: center;
                        margin-right: 20px;
                        h4 {
                            margin: 0;
                            font-weight: 600;
                            font-size: 10px;
                            color: #0000003d;
                            margin-right: 20px;
                        }
                        &-container {
                            display: flex;
                            &-box {
                                height: 32px;
                                width: 32px;
                                cursor: pointer;
                                border-radius: 6px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: background-color 200ms ease;
                            }
                            &-box:first-child {
                                margin-right: 5px;
                            }
                            .active {
                                background-color: #000;
                            }
                            .inactive {
                                background-color: #f3f3f3;
                            }
                        }
                    }
                }
                &-middle {
                    display: flex;
                    width: 100%;
                    height: 90vh;
                    &.lead-registration {
                        height: 520px;
                    }
                    .control-section {
                        width: 35%;
                        padding: 20px 40px 20px 0;
                        overflow-y: scroll;
                        &::-webkit-scrollbar {
                            width: 4px !important;
                            display: block !important;
                        }
                        &::-webkit-scrollbar-thumb {
                            width: 2px !important;
                            border-radius: 10px;
                            background-color: rgba(0, 0, 0, 0.2);
                        }
                        &::-webkit-scrollbar-track {
                            background: rgba(0, 0, 0, 0.04) !important;
                            width: 2px !important;
                        }
                        & .control-lead {
                            margin-top: 32px;
                            display: flex;
                            flex-direction: column;
                            row-gap: 40px;

                            .lead {
                                position: relative;
                                h4 {
                                    font-style: normal;
                                    font-weight: 700;
                                    font-size: 10px;
                                    line-height: 12px;
                                    text-transform: uppercase;
                                    color: #000000;
                                    margin: 0px;
                                    padding: 0px;
                                }
                                p {
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 10px;
                                    line-height: 14px;
                                    color: rgba(0, 0, 0, 0.5);
                                    margin: 0px;
                                    padding: 0px;
                                    margin-top: 4px;
                                    max-width: 216px;
                                }

                                .tick {
                                    width: 24px;
                                    height: 24px;
                                    position: absolute;
                                    right: 0px;
                                    top: 0px;
                                    cursor: pointer;
                                }
                            }
                        }
                        &-info {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 10px;
                            background: #f3f3f3;
                            border-radius: 6px;
                            img {
                                margin-right: 10px;
                            }
                            h4 {
                                margin: 0;
                                color: #00000080;
                                font-size: 12px;
                                font-weight: 600;
                                line-height: 16px;
                            }
                        }
                        &-looks {
                            font-weight: 700;
                            font-size: 12px;
                            margin-top: 25px;
                        }
                        &-size {
                            font-weight: 700;
                            font-size: 10px;
                            text-transform: uppercase;
                            margin-top: 30px;

                            &.mb-10.disable {
                                pointer-events: none;
                                opacity: 0.25;
                            }
                        }
                        &-selectors {
                            height: 34px;
                            background-color: #fff;
                            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
                            border-radius: 6px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 2px;
                            position: relative;
                            &.disable {
                                pointer-events: none;
                                opacity: 0.25;
                            }
                            .before-after::before {
                                content: '';
                                position: absolute;
                                height: 18px;
                                width: 1px;
                                background: rgba(0, 0, 0, 0.08);
                                border-radius: 12px;
                                top: 50%;
                                left: 33.6%;
                                transform: translateY(-50%);
                            }
                            .before-after::after {
                                content: '';
                                position: absolute;
                                height: 18px;
                                width: 1px;
                                background: rgba(0, 0, 0, 0.08);
                                border-radius: 12px;
                                top: 50%;
                                right: 33.6%;
                                transform: translateY(-50%);
                            }
                            &-sizes {
                                color: rgba(0, 0, 0, 0.5);
                                font-size: 10px;
                                font-weight: 700;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-basis: 33%;
                                cursor: pointer;
                                height: 100%;
                            }
                            .mobile {
                                flex-basis: 50%;
                            }
                            .active {
                                background-color: #000;
                                color: #fff;
                                border-radius: 4px;
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
                                transition: background-color 200ms ease;
                            }
                            &-position {
                                color: rgba(0, 0, 0, 0.5);
                                font-size: 10px;
                                font-weight: 700;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-basis: 50%;
                                cursor: pointer;
                                height: 100%;
                            }
                        }
                        .general-switch {
                            align-items: flex-end;
                            &-info {
                                display: flex;
                                width: calc(100% - 40px);

                                img {
                                    margin-right: 5px;
                                }
                                span {
                                    font-size: 10px;
                                    font-weight: 600;
                                    color: #00000080;
                                }
                            }
                        }
                        .video-upload {
                            margin-top: 10px;
                            height: 90px;
                            border-radius: 6px;
                            border: 1px dashed #00000080;
                            font-weight: bold;
                            font-size: 10px;
                            text-transform: uppercase;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                            color: rgba(0, 0, 0, 0.5);
                            position: relative;
                            & .video-remove {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-weight: 700;
                                font-size: 10px;
                                line-height: 12px;
                                text-transform: uppercase;
                                color: #ffffff;
                                display: flex;
                                align-items: center;
                                img {
                                    width: 16px;
                                    height: 16px;
                                }
                            }

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 6px;
                            }
                        }
                        .video-upload-size {
                            font-weight: 600;
                            font-size: 10px;
                            text-align: center;
                            color: rgba(0, 0, 0, 0.5);
                            margin-top: 10px;
                        }
                        hr {
                            border-color: rgba(0, 0, 0, 0.15);
                            margin: 30px 0;
                        }
                        &-padding {
                            position: relative;
                            border-radius: 8px;
                            overflow: hidden;
                            border: 1px solid #e5e5e5;
                            img {
                                height: auto;
                                width: 100%;
                                display: block;
                            }

                            .styling {
                                height: 17px;
                                width: 17px;
                                padding: unset !important;
                                font-size: 10px !important;
                                position: absolute;
                                border-radius: 2px;
                                text-align: center;
                                background: rgba(0, 0, 0, 0.08);
                                z-index: 2;
                            }
                            .side {
                                bottom: 50%;
                                transform: translateY(50%);
                                left: 3.3%;
                            }
                            .side-right {
                                bottom: 50%;
                                transform: translateY(50%);
                                right: 3.3%;
                            }

                            .bottom {
                                bottom: 5%;
                                right: 50%;
                                transform: translateX(50%);
                            }

                            .top {
                                top: 5%;
                                right: 50%;
                                transform: translateX(50%);
                            }
                        }
                    }
                    .preview-section {
                        width: 65%;
                        background-color: #000;
                        position: relative;
                        .preview-dots {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: 50px;
                            z-index: 2;
                        }
                        .preview-left {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 230px;
                        }
                        .preview-right {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 230px;
                        }
                        h4 {
                            margin: 0;
                            position: absolute;
                            top: 30px;
                            left: 30px;
                            color: rgba(255, 255, 255, 0.6);
                            font-size: 10px;
                            font-weight: 700;
                            text-transform: uppercase;
                        }
                        &-container {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            border-radius: 17px;
                            overflow: hidden;
                            transition: all 0.5s ease-in-out;

                            video {
                                height: 100% !important;
                                width: 100% !important;
                            }
                        }
                        img.preview-bg {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 17px;
                        }
                        .small {
                            height: 130px;
                            width: 96px;
                        }
                        .medium {
                            height: 236px;
                            width: 145px;
                        }
                        .large {
                            height: 358px;
                            width: 190px;
                        }
                        .extra-small {
                            height: 54px;
                            width: 54px;
                            border-radius: 100%;
                        }
                        .mobile-small {
                            height: 54px;
                            width: 54px;
                            border-radius: 50%;
                        }
                        .entry-large {
                            height: 150px;
                            width: 450px;
                        }
                        img.label {
                            position: absolute;
                            top: 12px;
                            left: 12px;
                        }
                        img.minimise {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                        }
                        img.small-play {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        img.filters {
                            position: absolute;
                            bottom: 20px;
                            left: 15px;
                        }
                        .eye {
                            display: flex;
                            align-items: center;
                            position: absolute;
                            bottom: 10px;
                            right: 14px;
                            font-size: 10px;
                            font-weight: 700;
                            color: #fff;
                            img {
                                margin-right: 3px;
                                height: 18px;
                            }
                        }
                        .full-screen {
                            height: 390px;
                            width: 200px;
                            .preview-bg {
                                box-shadow: 0px 5.49987px 32.9993px rgba(226, 225, 220, 0.4);
                            }
                            .price-container {
                                position: absolute;
                                bottom: 50px;
                                left: 15px;
                                font-weight: 800;
                                font-size: 12px;
                                line-height: 12px;
                                color: #fff;
                                z-index: 2;
                                display: flex;
                                column-gap: 4px;
                                align-items: flex-end;
                                .currency-type {
                                    font-weight: 700;
                                    color: #fff;
                                    font-size: 8px;
                                    line-height: 9px;
                                }
                                .price-type {
                                    font-weight: 700;
                                    color: rgba(255, 255, 255, 0.72);
                                    font-size: 8px;
                                    line-height: 9px;
                                }
                            }
                            .fs-address {
                                position: absolute;
                                bottom: 30px;
                                left: 15px;
                                font-weight: 800;
                                color: rgba(255, 255, 255, 0.72);
                                z-index: 2;
                                font-size: 7px;
                                // margin-top: 4px;
                                max-width: 128px;
                            }
                            .fs-details {
                                position: absolute;
                                bottom: 10px;
                                left: 15px;
                                font-weight: 800;
                                color: rgba(255, 255, 255, 0.72);
                                z-index: 2;
                                display: flex;
                                align-items: center;
                                column-gap: 6px;
                            }
                            .preview-assets {
                                position: absolute;
                                bottom: 5px;
                                right: 2px;
                                width: 45px;
                                z-index: 2;
                            }
                            .preview-details {
                                position: absolute;
                                z-index: 2;
                                left: 10px;
                                bottom: 7px;
                                width: 140px;
                            }

                            .preview-left {
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                height: 230px;
                            }
                            .preview-right {
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                height: 230px;
                            }
                            .preview-type {
                                position: absolute;
                                left: 10px;
                                bottom: 7px;
                                z-index: 2;
                            }
                            .preview-skills {
                                position: absolute;
                                top: 5px;
                                left: 0;
                                width: 200px;
                            }
                            .lead-container {
                                position: absolute;
                                bottom: 0px;
                                left: 0px;
                                width: 100%;
                                height: 285px;
                                border-radius: 8px 8px 0px 0px;
                                // background: rgba(0, 0, 0, 0.08);
                                // box-shadow: 0px -6.03107px 18.0932px rgba(0, 0, 0, 0.1);
                                // backdrop-filter: blur(24.1243px);
                                & img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    border-radius: 10px;
                                }
                            }
                        }
                        .full-screen:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 100px;
                            background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
                            transform: matrix(1, 0, 0, -1, 0, 0);
                            left: 0;
                        }
                        .lead-registration-screen::after {
                            content: none !important;
                        }
                    }

                    .preview-section-home {
                        width: 65%;
                        background-color: #000;
                        position: relative;
                        overflow: hidden;
                        .preview-dots {
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: 70px;
                            z-index: 2;
                        }
                        .preview-left {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 230px;
                        }
                        .preview-right {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            height: 230px;
                        }
                        h4 {
                            margin: 0;
                            position: absolute;
                            top: 30px;
                            left: 30px;
                            color: rgba(255, 255, 255, 0.6);
                            font-size: 10px;
                            font-weight: 700;
                            text-transform: uppercase;
                        }
                        &-container {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            border-radius: 17px;
                            overflow: hidden;
                        }
                        img.entry-bg {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 10px;
                        }
                        img.preview-bg {
                            height: 100%;
                            width: 100%;
                            object-fit: cover;
                            border-radius: 17px;
                        }
                        .small {
                            height: 54px;
                            width: 54px;
                        }
                        .entry-medium {
                            height: 130px;
                            width: 96px;
                        }
                        .large {
                            height: 154px;
                            width: 486px;
                            border-radius: 10px;
                        }
                        .mobile-large {
                            height: 200px;
                            width: 345px;
                            border-radius: 10px;
                        }
                        .entry-small {
                            height: 54px;
                            width: 54px;
                            border-radius: 100%;
                        }
                        .entry-large {
                            height: 150px;
                            width: 450px;
                        }
                        img.label {
                            position: absolute;
                            top: 12px;
                            left: 12px;
                        }
                        img.minimise {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                        }
                        img.filters {
                            position: absolute;
                            bottom: 20px;
                            left: 15px;
                        }
                        .search-box {
                            position: absolute;
                            bottom: 40px;
                            left: 25px;
                        }
                        .search-box-mobile {
                            position: absolute;
                            bottom: 75px;
                            left: 25px;
                        }
                        .filters-container {
                            position: absolute;
                            left: 25px;
                            bottom: 25px;
                            width: 100%;
                        }
                        .search-icon-only {
                            position: absolute;
                            top: 58%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        .search-icon-only-m {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        .search-icon {
                            padding-bottom: 5px;
                            border-bottom: 0.75px solid rgba(255, 255, 255, 0.33);
                            display: flex;
                            span {
                                font-weight: 700;
                                font-size: 14px;
                                color: #bdbdbd;
                            }
                        }
                        .filters-dd {
                            margin-top: 8px;
                            display: flex;
                            &-type {
                                display: flex;
                                align-items: center;
                                margin-right: 15px;
                                font-weight: 700;
                                font-size: 9px;
                                color: #ffffff;
                            }
                        }
                        .filters-desktop {
                            position: absolute;
                            left: 28px;
                            bottom: 20px;
                        }
                        .filters-mobile {
                            position: absolute;
                            left: 28px;
                            bottom: 55px;
                        }
                        .powered {
                            position: absolute;
                            right: 10px;
                            bottom: 10px;
                        }
                        .full-screen {
                            height: 390px;
                            width: 200px;
                            .preview-bg {
                                box-shadow: 0px 5.49987px 32.9993px rgba(226, 225, 220, 0.4);
                            }
                            .price-container {
                                position: absolute;
                                bottom: 50px;
                                left: 15px;
                                font-weight: 800;
                                font-size: 12px;
                                line-height: 12px;
                                color: #fff;
                                z-index: 2;
                                display: flex;
                                column-gap: 4px;
                                align-items: flex-end;
                                .currency-type {
                                    font-weight: 700;
                                    color: #fff;
                                    font-size: 8px;
                                    line-height: 9px;
                                }
                                .price-type {
                                    font-weight: 700;
                                    color: rgba(255, 255, 255, 0.72);
                                    font-size: 8px;
                                    line-height: 9px;
                                }
                            }
                            .fs-address {
                                position: absolute;
                                bottom: 30px;
                                left: 15px;
                                font-weight: 800;
                                color: rgba(255, 255, 255, 0.72);
                                z-index: 2;
                                font-size: 7px;
                                // margin-top: 4px;
                                max-width: 128px;
                            }
                            .fs-details {
                                position: absolute;
                                bottom: 10px;
                                left: 15px;
                                font-weight: 800;
                                color: rgba(255, 255, 255, 0.72);
                                z-index: 2;
                                display: flex;
                                align-items: center;
                                column-gap: 6px;
                            }
                            .preview-assets {
                                position: absolute;
                                bottom: 0px;
                                right: 0px;
                                width: 45px;
                                z-index: 2;
                            }
                            .preview-details {
                                position: absolute;
                                z-index: 2;
                                left: 10px;
                                bottom: 7px;
                                width: 140px;
                            }

                            .preview-left {
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                height: 230px;
                            }
                            .preview-right {
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                height: 230px;
                            }
                            .preview-type {
                                position: absolute;
                                left: 10px;
                                bottom: 7px;
                                z-index: 2;
                            }
                            .preview-skills {
                                position: absolute;
                                top: 5px;
                                left: 0;
                                width: 200px;
                            }
                        }
                        .full-screen:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            width: 100%;
                            height: 100px;
                            background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
                            transform: matrix(1, 0, 0, -1, 0, 0);
                            left: 0;
                        }
                    }
                }
            }
            &-homepage {
                display: flex;
                &-info {
                    width: 45%;
                    padding: 25px;
                    h4 {
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 28px;
                        margin: 0;
                    }
                    p {
                        margin-top: 20px;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: rgba(0, 0, 0, 0.5);
                        span {
                            color: #000;
                        }
                    }
                }
                &-image {
                    width: 55%;
                    padding: 19px 25px 25px 50px;
                    img {
                        width: 100%;
                    }
                }
            }
            &-pair {
                background: #ececec;
                border-radius: 6px;
                padding: 20px;
                margin-top: 20px;
                h4 {
                    margin: 0;
                    font-weight: bold;
                    font-size: 14px;
                }
                label {
                    font-weight: bold;
                    font-size: 10px;
                    text-transform: uppercase;
                    color: #000000;
                    margin-top: 30px;
                    display: block;
                }
                input {
                    margin-top: 5px;
                    &.custom-placeholder::placeholder {
                        color: #000 !important;
                    }
                }
                .loading-status {
                    margin-top: 5px;
                    height: 48px;
                    border: 1px solid rgba(0, 0, 0, 0.06);
                    border-radius: 6px;
                    font-weight: bold;
                    font-size: 10px;
                    color: rgba(0, 0, 0, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .loader-animation {
                        margin-right: 10px;
                        svg {
                            height: 20px;
                            width: 20px;
                        }
                    }
                }

                & .webpage-input-container-error {
                    margin-top: 16px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: #e00000;
                }
                & .warning-wrapper {
                    background: #ffcf88;
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 8px;
                    padding: 16px 18px 16px 26px;
                    box-sizing: border-box;
                    position: relative;
                    & .warning-text {
                        margin: 0px 16px 0px 14px;
                        box-sizing: border-box;
                        // max-width: 356px;
                        & .title {
                            margin: 0;
                            font-weight: bold;
                            font-size: 10px;
                            line-height: 12px;
                            color: rgba(0, 0, 0, 0.5);
                            text-align: center;
                        }
                        & .subtitle {
                            font-weight: 600;
                            font-size: 10px;
                            line-height: 14px;
                            margin: 4px 0 0 0;
                            color: rgba(0, 0, 0, 0.32);
                            text-align: center;
                        }
                    }

                    & .warning-btn {
                        font-weight: bold;
                        font-size: 10px;
                        line-height: 12px;
                        // text-align: center;
                        text-decoration-line: underline;
                        text-transform: uppercase;
                        cursor: pointer;
                        color: rgba(0, 0, 0, 0.5);

                        right: 18px;
                    }
                }
                & .success-pair {
                    background: #c9ffc4;
                    border-radius: 8px;
                    box-sizing: border-box;
                    height: 52px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    column-gap: 16px;
                    margin-top: 8px;
                    & .msg {
                        font-weight: bold;
                        font-size: 10px;
                        line-height: 12px;
                        color: rgba(0, 0, 0, 0.5);
                        display: flex;
                        align-items: center;
                        column-gap: 5px;
                    }
                    & .view-btn {
                        font-weight: bold;
                        font-size: 10px;
                        line-height: 12px;
                        text-decoration-line: underline;
                        text-transform: uppercase;
                        color: rgba(0, 0, 0, 0.5);
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.widget-settings-accordion:last-child {
    margin-bottom: 150px;
}

.accordion__button {
    outline-color: unset !important;
    outline-style: unset !important;
    outline-width: unset !important;
}
