.dragtip-container {
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 271px;
  height: 362px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  & .img-wrapper {
    height: 50%;
    position: relative;
    border-radius: 8px;
    & img {
      width: 100%;
      height: 100%;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    & .drag-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 8px;
      right: 8px;
      background: rgba(0, 0, 0, 0.56);
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      & img {
        width: 10px;
        height: 10px;
      }
    }
  }
  & .content-wrapper {
    height: 50%;
    padding: 24px;
    text-align: center;
    & p {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #000000;
      text-align: left;
    }
    & .drag-gt {
      margin: 0px;
      padding: 10px 32px;
      border: 1px solid rgba(0, 0, 0, 0.56);
      box-sizing: border-box;
      border-radius: 6px;
      font-weight: 700  ;
      font-size: 10px;
      line-height: 12px;
      text-transform: uppercase;
      text-align: center;
      color: rgba(0, 0, 0, 0.56);
      margin-top: 25px;
      cursor: pointer;
    }
  }
}