.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    min-width: 35px;
    height: 20px;
    min-height: 20px;
    .switch-input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .switch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 18px;
        transition: all 0.2s ease-in-out;
        &::before {
            position: absolute;
            content: '';
            width: 16px;
            height: 16px;
            border-radius: 100%;
            left: 2px;
            bottom: 2px;
            background: white;
            transition: all 0.2s ease-in-out;
            box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        }
    }
    .switch-input:checked + .switch-slider::before {
        left: calc(100% - 2px);
        transform: translateX(-100%);
    }
    .checked {
        background-color: #0d25ff;
    }
    .unchecked {
        background-color: rgba(0, 0, 0, 0.06);
    }
}
