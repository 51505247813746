.overall-space-container {
    width: 900px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 560px;
    height: 550px !important;
    box-sizing: border-box;
    // padding: 0 20px;
    margin-top: 40px;
    &::-webkit-scrollbar {
        display: none !important;
    }
    // Space Details Container
    .space-details-container {
        width: 100% !important;
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        padding: 0 150px !important;
        margin: 0px !important;
        &::-webkit-scrollbar {
            width: 4px !important;
            display: block !important;
        }
        &::-webkit-scrollbar-thumb {
            width: 4px !important;
            border-radius: 10px;
            background-color: #adadad;
        }
        &::-webkit-scrollbar-track {
            background: #dcdcdc !important;
            width: 4px !important;
        }
        .space-address-input {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 45px;
            label {
                font-size: 10px;
                line-height: 12px;
                font-weight: 700;
            }
            &.create-space {
                margin-top: 0px;
            }
            .space-address-input-wrapper {
                margin-top: 5px;
                border-radius: 6px;
                display: flex;
                flex-direction: row;
                padding: 15px;
                align-items: center;
                justify-content: space-between;
                background: rgba(0, 0, 0, 0.08);
                height: 44px;
                box-sizing: border-box;
                .space-address-text-wrapper {
                    display: flex;
                    font-size: 12px;
                    line-height: 12px;
                    font-weight: 600;
                }
                img {
                    margin: 0px 14px 0px 0px;
                }
                button {
                    font-size: 10px;
                    line-height: 12px;
                    font-weight: 700;
                    opacity: 0.5;
                    text-decoration: underline;
                }
            }
        }
        .space-actions-input {
            padding: 10px;
        }

        // Space Type
        .space-type-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            label {
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
                margin-bottom: 5px;
            }
            select {
                appearance: none;
                padding: 15px;
                border: none;
                border-radius: 6px;
                font-size: 12px;
                line-height: 12px;
                font-weight: 700;
                outline: none;
                -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
                -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            }
            img {
                position: absolute;
                right: 16px;
                top: 35px;
            }
        }
    }
}
.space-field {
    width: 100%;
    margin-top: 30px;
    // Space Type
    .space-type-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        label {
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            margin-bottom: 5px;
        }
        select {
            appearance: none;
            padding: 15px;
            border: none;
            border-radius: 6px;
            font-size: 12px;
            line-height: 12px;
            font-weight: 700;
            outline: none;
            -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        }
        img {
            position: absolute;
            right: 16px;
            top: 35px;
        }
    }
}

.bottom-wrapper {
    button {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #000;
        border: none;
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        height: 40px;
        border-radius: 6px;
    }
}
.err-container {
    width: 330px;
    background: #050505;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    box-sizing: border-box;
    position: relative;
    margin-top: -30px;
    &-title {
        padding: 20px 18px 15px 18px;
        display: grid;
        grid-template-columns: 3fr 2fr 1fr;
        h4 {
            color: #bfbfbf;
            font-weight: 700;
            font-size: 10px;
            margin: 0;
            text-transform: uppercase;
        }
    }
    &-heading {
        &-rating {
            display: flex;
            flex-direction: column;
            padding-bottom: 18px;
            &-content {
                min-height: 35px;
                align-items: center;
                display: grid;
                grid-template-columns: 2fr 1.7fr 1.8fr;
                width: 100%;
                border-bottom: 1px solid rgba(255, 255, 255, 0.08);
                &-details {
                    color: #808080;
                    font-weight: 700;
                    font-size: 12px;
                    display: flex;
                    align-items: center;
                    margin-left: 18px;
                    span {
                        font-weight: 700;
                        font-size: 15px;
                        text-transform: uppercase;
                        color: #fff;
                        height: 20px !important;
                        width: 20px !important;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 8px;
                    }
                }
                &-cur {
                    width: 50px;
                    height: 28px;
                    border-top-right-radius: 50px;
                    border-bottom-right-radius: 50px;
                    border-top-left-radius: 25px;
                    border-bottom-left-radius: 25px;
                    margin-left: 40px;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    div {
                        position: absolute;
                        left: -13px;
                        width: 0;
                        height: 0;
                        border-top: 14px solid transparent;
                        border-bottom: 14px solid transparent;
                    }
                    span {
                        color: #fff;
                        font-weight: 700;
                        font-size: 16px;
                        padding-right: 6px;
                    }
                }
            }
        }
    }
}

.err-container::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid black;
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
}

.bg-a {
    background: #157a41;
}
.bg-b {
    background: #2e9e47;
}
.bg-c {
    background: #8cc153;
}
.bg-d {
    background: #efe746;
}
.bg-e {
    background: #f4a33c;
}
.bg-f {
    background: #ec6331;
}
.bg-g {
    background: #e4212e;
}
.bg-a-shadow {
    box-shadow: 0px 0px 10px #157a41;
}
.bg-b-shadow {
    box-shadow: 0px 0px 10px #2e9e47;
}
.bg-c-shadow {
    box-shadow: 0px 0px 10px #8cc153;
}
.bg-d-shadow {
    box-shadow: 0px 0px 10px #efe746;
}
.bg-e-shadow {
    box-shadow: 0px 0px 10px #f4a33c;
}
.bg-f-shadow {
    box-shadow: 0px 0px 10px #ec6331;
}
.bg-g-shadow {
    box-shadow: 0px 0px 10px #e4212e;
}

.bg-a-border {
    border-right: 18px solid #157a41;
}
.bg-b-border {
    border-right: 18px solid #2e9e47;
}
.bg-c-border {
    border-right: 18px solid #8cc153;
}
.bg-d-border {
    border-right: 18px solid #efe746;
}
.bg-e-border {
    border-right: 18px solid #f4a33c;
}
.bg-f-border {
    border-right: 18px solid #ec6331;
}
.bg-g-border {
    border-right: 18px solid #e4212e;
}

.bg-a-gradient {
    background: linear-gradient(270deg, rgba(46, 158, 71, 0.42) 0%, rgba(46, 158, 71, 0) 100%);
}
.bg-b-gradient {
    background: linear-gradient(270deg, rgba(46, 158, 71, 0.42) 0%, rgba(46, 158, 71, 0) 100%);
}
.bg-c-gradient {
    background: linear-gradient(270deg, rgba(140, 193, 83, 0.42) 0%, rgba(140, 193, 83, 0) 100%);
}
.bg-d-gradient {
    background: linear-gradient(
        270deg,
        rgba(214, 207, 69, 0.24) 0%,
        rgba(214, 207, 69, 0.24) 0.01%,
        rgba(0, 0, 0, 0.24) 100%,
        rgba(214, 207, 69, 0) 100%
    );
}
.bg-e-gradient {
    background: linear-gradient(270deg, rgba(244, 163, 60, 0.42) 0%, rgba(244, 163, 60, 0) 100%);
}
.bg-f-gradient {
    background: linear-gradient(270deg, rgba(236, 99, 49, 0.42) 0%, rgba(236, 99, 49, 0) 100%);
}
.bg-g-gradient {
    background: linear-gradient(270deg, rgba(228, 33, 46, 0.42) 0%, rgba(228, 33, 46, 0) 100%);
}
