.empty-state-wrapper {
    width: 100%;
    height: 600px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 20px;
    margin-top: 20px;
    overflow: hidden;
    display: flex;
    align-items: center;

    a {
        margin-top: 25px;
        text-transform: uppercase;
        font-size: 10px;
        line-height: 12px;
        font-weight: 700;
        opacity: 0.5;
        text-decoration: underline;
    }

    .section-one {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(270deg, rgba(243, 243, 243, 0.8) 0%, rgba(255, 255, 255, 0.8) 100.72%);
        .bg {
            width: 100%;
            height: 100%;
            opacity: 0.5;
            position: absolute;
        }
        .content-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 10;
            .testimonial-image {
                width: 188px;
            }
            h1 {
                margin: 0;
                font-size: 16px;
                line-height: 18px;
                font-weight: 700;
                margin-top: 25px;
            }
            p {
                margin: 0;
                margin-top: 8px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                opacity: 0.5;
            }
            .testimonial-quote {
                width: 28px;
                margin-top: 50px;
                opacity: 0.2;
            }
            .testimonial-text {
                margin-top: 20px;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                opacity: 1;
                text-align: center;
                width: 60%;
            }
        }
    }

    .section-two {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 50%;
        height: 100%;
        .section-two-partone {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            width: 100%;
            height: 45%;

            h1 {
                font-size: 24px;
                line-height: 28px;
            }
            .section-two-partone-button-wrapper {
                display: flex;
                align-items: center;
                button {
                    width: 120px;
                    height: 40px;
                    margin-left: 10px;
                    &:first-child {
                        margin-left: 0px;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .section-two-parttwo {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            text-align: center;

            width: 100%;
            height: 10%;
            .section-two-line {
                height: 1px;
                background-color: black;
                width: 40%;
                opacity: 0.2;
            }
            p {
                width: 10%;
                opacity: 0.2;
                font-size: 12px;
                font-weight: 600;
            }
        }
        .section-two-partthree {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 45%;
            h1 {
                font-size: 24px;
                line-height: 28px;
                margin: 0;
            }
            p {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                opacity: 0.5;
            }
        }
    }
}

// Buttons
.empty-state-add-new {
    min-width: 258px;
    min-height: 48px;
    background-color: black;
    padding: 15px 30px;
    color: white;
    font-size: 12px;
    border-radius: 6px;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    &:hover {
        box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
    }
}

@media screen and (max-width: '1020px') {
    .empty-state-height {
        height: calc(100vh - 300px);
    }
}

@media screen and (min-width: 1900px) {
    .empty-state-height {
        padding-top: 60px;
    }
    .custom-bottom {
        bottom: 70px;
    }
}

.empty-state-height {
    height: calc(100vh - 250px);
    margin-top: 20px;
    box-shadow: 0px 100px 200px rgba(0, 0, 0, 0.15);
    padding-top: 10px;
}

.custom-bottom {
    bottom: 35px;
}

.first-space-container {
    &-item {
        height: 220px;
        width: 220px;
        border-radius: 15px;
        border: 1px dashed #898989;
        left: 50%;
        transform: translate(-50%, 10px);
        position: relative;
        &-plus {
            width: 60px;
            height: 4px;
            background-color: rgba(57, 57, 57, 0.16);
            &::after {
                content: '';
                height: 60px;
                width: 4px;
                background-color: rgba(57, 57, 57, 0.16);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &::before {
                content: '';
                height: 4px;
                width: 4px;
                background-color: rgb(241, 241, 241);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
    h4 {
        font-size: 24px;
        margin-top: 40px;
        margin-bottom: 15px;
    }
    span {
        display: block;
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
    }
}
.payment-loader {
    display: flex;
    justify-content: center;
    align-items: center;
}
