.edit-profile-container {
    .wrapper {
        width: 100%;
        height: 100%;
        padding: 120px 170px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        row-gap: 25px;
        overflow-y: scroll;
        z-index: 999999;
        &::-webkit-scrollbar {
            width: 4px !important;
            display: block !important;
        }
        &::-webkit-scrollbar-thumb {
            width: 4px !important;
            border-radius: 10px;
            background-color: #adadad;
        }
        &::-webkit-scrollbar-track {
            background: #dcdcdc !important;
            width: 4px !important;
        }
    }
}
