// Display
.d-flex {
    display: flex;
}
.align-self-c {
    align-self: center;
}
.align-self-start {
    align-self: flex-start;
}
.d-inflex {
    display: inline-flex;
}
.d-ib {
    display: inline-block;
}
.d-none {
    display: none;
}
.z-1 {
    z-index: 1;
}

.z-100 {
    z-index: 100;
}

.flex-col {
    flex-direction: column;
}

// Visibility
.v-hidden {
    visibility: hidden;
}
.text-center {
    text-align: center;
}
// Flex
.center-align {
    justify-content: center;
    align-items: center;
}

.justify-end {
    justify-content: flex-end;
}

//Non-Flex
.center-fix {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.flex-space-around {
    justify-content: space-around;
}
.left-align {
    justify-content: center;
    align-items: start;
}
.vertical-center {
    align-items: center;
}
.h-center {
    align-items: center;
}
.v-center {
    justify-content: center;
}
.vertical-bottom {
    align-items: flex-end;
}
.space-between {
    justify-content: space-between;
}
.f-col {
    flex-direction: column;
}
.f-wrap {
    flex-wrap: wrap;
}
.f-flow-wrap {
    flex-flow: wrap;
}
.f-g1 {
    flex-grow: 1;
}

//text-transform

.uppercase {
    text-transform: uppercase;
}

// Position
.pos-rel {
    position: relative;
}
.pos-abs {
    position: absolute;
}
.pos-fix {
    position: fixed;
}

.top-0 {
    top: 0;
}
.top-58p {
    top: 58%;
}
.right-0 {
    right: 0;
}
.right-20 {
    right: 20px;
}
.bottom-0 {
    bottom: 0;
}
.bottom-10 {
    bottom: 10px;
}
.bottom-20 {
    bottom: 20px;
}
.bottom-25 {
    bottom: 25px;
}
.bottom-30 {
    bottom: 30px;
}
.bottom-35 {
    bottom: 35px;
}
.left-0 {
    left: 0;
}
.bottom-35 {
    bottom: 35px;
}
// Width
.w-30 {
    width: 30px;
}
.w-40 {
    width: 40px;
}
.w-50 {
    width: 50px;
}
.w-60 {
    width: 60px;
}
.w-64 {
    width: 64px;
}
.w-70 {
    width: 70px;
}
.w-75 {
    width: 75px;
}
.w-80 {
    width: 80px;
}
.w-100 {
    width: 100px;
}
.w-115 {
    width: 110px;
}
.w-118 {
    width: 118px;
}
.w-120 {
    width: 120px;
}
.w-140 {
    width: 140px;
}
.w-150 {
    width: 150px;
}
.w-200 {
    width: 200px;
}
.w-280 {
    width: 280px;
}
.w-300 {
    width: 300px;
}
.w-310 {
    width: 310px;
}
.w-320 {
    width: 320px;
}
.w-340 {
    width: 340px;
}
.w-360 {
    width: 360px;
}
.w-380 {
    width: 380px;
}
.w-400 {
    width: 400px;
}
.w-470 {
    width: 470px;
}
.w-580 {
    width: 580px;
}
.mnw-unset {
    min-width: unset;
}
.mnw-30 {
    min-width: 30px;
}
.mnw-40 {
    min-width: 40px;
}
.mnw-50 {
    min-width: 50px;
}
.mnw-60 {
    min-width: 60px;
}
.mnw-70 {
    min-width: 70px;
}
.mnw-75 {
    min-width: 75px;
}
.mnw-80 {
    min-width: 85px;
}
.mnw-100 {
    min-width: 100px;
}
.mnw-110 {
    min-width: 110px;
}
.mnw-120 {
    min-width: 120px;
}
.mnw-130 {
    min-width: 130px;
}
.mnw-150 {
    min-width: 150px;
}
.mnw-170 {
    min-width: 170px;
}
.mnw-200 {
    min-width: 200px;
}
.mnw-225 {
    min-width: 225px;
}
.mnw-250 {
    min-width: 250px;
}
.mnw-260 {
    min-width: 260px;
}
.mnw-280 {
    min-width: 280px;
}
.mnw-320 {
    min-width: 320px;
}
.mnw-370 {
    min-width: 370px;
}
.mnw-700 {
    min-width: 700px;
}
.mnw-720 {
    min-width: 720px;
}
.mnw-870 {
    min-width: 870px;
}
.mnw-950 {
    min-width: 950px;
}
.mnw-1230 {
    min-width: 1230px;
}
.mnw-30p {
    min-width: 30%;
}
.mnw-40p {
    min-width: 40%;
}
.mnw-60p {
    min-width: 60%;
}
.mnw-70p {
    min-width: 70%;
}
.mnw-80p {
    min-width: 80%;
}
.mxw-40 {
    max-width: 40px;
}
.mxw-50 {
    max-width: 50px;
}
.mxw-60 {
    max-width: 60px;
}
.mxw-70 {
    max-width: 70px;
}
.mxw-75 {
    max-width: 75px;
}
.mxw-90 {
    max-width: 90px;
}
.mxw-100 {
    max-width: 100px;
}
.mxw-110 {
    max-width: 110px;
}
.mxw-120 {
    max-width: 120px;
}
.mxw-130 {
    max-width: 130px;
}
.mxw-150 {
    max-width: 150px;
}
.mxw-190 {
    max-width: 190px;
}
.mxw-200 {
    max-width: 200px;
}
.mxw-205 {
    max-width: 205px;
}
.mxw-225 {
    max-width: 225px;
}
.mxw-250 {
    max-width: 250px;
}
.mxw-280 {
    max-width: 280px;
}
.mxw-300 {
    max-width: 300px;
}
.mxw-320 {
    max-width: 320px;
}
.mxw-360 {
    max-width: 360px;
}
.mxw-380 {
    max-width: 380px;
}
.mxw-700 {
    max-width: 700px;
}
.mxw-720 {
    max-width: 720px;
}
.mxw-870 {
    min-width: 870px;
}
.mxw-370 {
    max-width: 370px;
}
.mxw-950 {
    max-width: 950px;
}
.mxw-1230 {
    max-width: 1230px;
}
.mxw-40p {
    max-width: 40%;
}
.mxw-60p {
    max-width: 60%;
}
.mxw-80p {
    max-width: 80%;
}
.mxw-100p {
    max-width: 100%;
}
.w-25p {
    width: 25%;
}
.w-75p {
    width: 75%;
}
.w-20p {
    width: 20%;
}
.w-30p {
    width: 30%;
}
.w-40p {
    width: 40%;
}
.w-50p {
    width: 50%;
}
.w-60p {
    width: 60%;
}
.w-65p {
    width: 65%;
}
.w-70p {
    width: 70%;
}
.w-80p {
    width: 80%;
}
.w-90p {
    width: 90%;
}

.w-95p {
    width: 95%;
}

.w-100p {
    width: 100%;
}
// Height
.h-10 {
    height: 10px;
}
.h-12 {
    height: 12px;
}
.h-15 {
    height: 15px;
}
.h-20 {
    height: 20px;
}
.h-40 {
    height: 40px;
}
.h-50 {
    height: 50px;
}
.h-60 {
    height: 60px;
}

.h-75 {
    height: 75px;
}
.h-100 {
    height: 100px;
}
.h-120 {
    height: 120px;
}
.h-95vh {
    height: 95vh;
}
.h-100vh {
    height: 100vh;
}

.h-105vh {
    height: 105vh;
}
.h-100p {
    height: 100%;
}
.mnh-130 {
    min-height: 130px;
}
.mnh-150 {
    min-height: 150px;
}
.mnh-180 {
    min-height: 180px;
}
.mnh-200 {
    min-height: 200px;
}
.mnh-390 {
    min-height: 390px;
}
.mnh-440 {
    min-height: 440px;
}
.mnh-610 {
    min-height: 610px;
}
.mnh-648 {
    min-height: 648px;
}
.mxh-200 {
    max-height: 200px;
}
.mxh-390 {
    max-height: 390px;
}
.mxh-440 {
    max-height: 440px;
}
.mnh-mnc {
    min-height: min-content;
}

// Margin
.m-10 {
    margin: 10px;
}
.m-15 {
    margin: 15px;
}
.m-20 {
    margin: 20px;
}
.m-30 {
    margin: 30px;
}
.m-40 {
    margin: 40px;
}
.m-15-25 {
    margin: 15px 25px;
}
.m-20-10 {
    margin: 20px 10px;
}
.mt-0 {
    margin-top: 0px;
}
.mt-2 {
    margin-top: 2px;
}
.mt-5 {
    margin-top: 5px;
}
.mt-6 {
    margin-top: 6px;
}
.mt-7 {
    margin-top: 7px;
}
.mt-8 {
    margin-top: 8px;
}
.mt-9 {
    margin-top: 9px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-18 {
    margin-top: 18px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-23 {
    margin-top: 23px;
}
.mt-25 {
    margin-top: 25px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-43 {
    margin-top: 43px;
}
.mt-50 {
    margin-top: 50px;
}
.mt-60 {
    margin-top: 60px;
}
.mt-70 {
    margin-top: 70px;
}
.mt-80 {
    margin-top: 80px;
}
.mt-100 {
    margin-top: 100px;
}
.mt--3 {
    margin-top: -3px;
}
.mt--5 {
    margin-top: -5px;
}

.mr-2 {
    margin-right: 2px;
}
.mr-5 {
    margin-right: 5px;
}
.mr-7 {
    margin-right: 7px;
}
.mr-10 {
    margin-right: 10px;
}
.mr-15 {
    margin-right: 15px;
}
.mr-20 {
    margin-right: 20px;
}
.mr-25 {
    margin-right: 25px;
}
.mr-30 {
    margin-right: 30px;
}
.mr-40 {
    margin-right: 40px;
}
.mr-45 {
    margin-right: 45px;
}
.mr-50 {
    margin-right: 50px;
}
.mr-60 {
    margin-right: 60px;
}
.mr-70 {
    margin-right: 70px;
}

.mb-0 {
    margin-bottom: 0px !important;
}
.mb-2 {
    margin-bottom: 2px;
}
.mb-3 {
    margin-bottom: 3px;
}
.mb-5 {
    margin-bottom: 5px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-15 {
    margin-bottom: 15px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-25 {
    margin-bottom: 25px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-33 {
    margin-bottom: 33px;
}
.mb-35 {
    margin-bottom: 35px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-43 {
    margin-bottom: 43px;
}
.mb-50 {
    margin-bottom: 50px;
}
.mb-60 {
    margin-bottom: 60px;
}
.mb-70 {
    margin-bottom: 70px;
}

.ml-2 {
    margin-left: 2px;
}
.ml-5 {
    margin-left: 5px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-15 {
    margin-left: 15px;
}
.ml-19 {
    margin-left: 19px;
}
.ml-20 {
    margin-left: 20px;
}
.ml-25 {
    margin-left: 25px;
}
.ml-30 {
    margin-left: 30px;
}
.ml-31 {
    margin-left: 31px;
}
.ml-35 {
    margin-left: 35px;
}
.ml-48 {
    margin-left: 48px;
}
.ml-50 {
    margin-left: 50px;
}
.ml-51 {
    margin-left: 51px;
}

// Padding
.p-3 {
    padding: 3px;
}
.p-5 {
    padding: 5px;
}
.p-10 {
    padding: 10px;
}
.p-12 {
    padding: 12px;
}
.p-15 {
    padding: 15px;
}
.p-20 {
    padding: 20px;
}
.p-25 {
    padding: 25px;
}

.p-5-3 {
    padding: 5px 3px;
}
.p-5-10 {
    padding: 5px 10px;
}
.p-5-15 {
    padding: 5px 15px;
}
.p-10-5 {
    padding: 10px 5px;
}
.p-10-20 {
    padding: 10px 20px;
}
.p-5-30 {
    padding: 5px 30px;
}
.p-15-30 {
    padding: 15px 30px;
}
.pt-5 {
    padding-top: 5px;
}
.pt-10 {
    padding-top: 10px;
}
.pt-15 {
    padding-top: 15px;
}
.pt-16 {
    padding-top: 16px;
}
.pt-18 {
    padding-top: 18px;
}
.pt-20 {
    padding-top: 20px;
}
.pt-25 {
    padding-top: 25px;
}
.pr-5 {
    padding-right: 5px;
}
.pr-10 {
    padding-right: 10px;
}
.pr-12 {
    padding-right: 12px;
}
.pr-15 {
    padding-right: 15px;
}
.pr-20 {
    padding-right: 20px;
}
.pr-25 {
    padding-right: 25px;
}
.pr-30 {
    padding-right: 30px;
}
.pr-40 {
    padding-right: 40px;
}
.pr-50 {
    padding-right: 50px;
}
.pr-58 {
    padding-right: 58px;
}
.pr-60 {
    padding-right: 60px;
}
.pr-80 {
    padding-right: 80px;
}
.pr-200 {
    padding-right: 200px;
}
.pb-2 {
    padding-bottom: 2px;
}
.pb-4 {
    padding-bottom: 4px;
}
.pb-10 {
    padding-bottom: 10px;
}
.pb-15 {
    padding-bottom: 15px;
}
.pb-19 {
    padding-bottom: 19px;
}
.pb-20 {
    padding-bottom: 20px;
}

.pl-5 {
    padding-left: 5px;
}
.pl-10 {
    padding-left: 10px;
}
.pl-15 {
    padding-left: 15px;
}
.pl-16 {
    padding-left: 16px;
}
.pl-20 {
    padding-left: 20px;
}
.pl-25 {
    padding-left: 25px;
}
.pl-30 {
    padding-left: 30px;
}
.pl-40 {
    padding-left: 40px;
}
.pl-50 {
    padding-left: 50px;
}

// Overflow
.ovf-auto {
    overflow: auto;
}
.ovf-hidden {
    overflow: hidden;
}
.ovf-scroll {
    overflow: scroll;
}
.ovf-y-scroll-show {
    overflow-y: hidden;
    scrollbar-gutter: stable;
}
.ovf-y-scroll-show:hover {
    overflow-y: scroll;
}
.ovf-y-scroll-show::-webkit-scrollbar {
    width: 2px !important;
    display: block !important;
}
.ovf-y-scroll-show::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
}
.ovf-y-scroll-show::-webkit-scrollbar-track {
    background: transparent !important;
}
.ovf-x-scroll {
    overflow-x: scroll;
}
.ovf-y-scroll {
    overflow-y: scroll;
}
.op-0 {
    opacity: 0;
}
