.company-name-container {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    margin-bottom: -30px;
    & label {
        font-size: 10px;
        line-height: 12px;
    }
    input {
        min-width: 252px;
        width: 252px;
        padding-right: 45px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
