.statusmsg-atom {
  @include media("<phone") {
    display: none;
  }

  @include media(">=phone", "<lgphone") {
    display: none;
  }
  @include media(">=lgphone", "<tablet") {
    display: none;
  }
}

.statusmsg-mobile {
  @include media(">tablet") {
    display: none;
  }

  @include media("<phone") {
    display: flex;
  }

  @include media(">=phone", "<lgphone") {
    display: flex;
  }
  @include media(">=lgphone", "<tablet") {
    display: flex;
  }
  .statusmsg-modal {
    @include media("<phone") {
      height: 30vh;
    }

    @include media(">=phone", "<lgphone") {
      height: 35vh;
    }
    @include media(">=lgphone", "<tablet") {
      height: 35vh;
    }
  }
}
