.remove-card-wrapper {
    width: 250px;
    // padding: 10px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(246, 101, 90, 0.81);
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px);

    .form-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .input-wrapper {
            width: 100%;
        }
    }
    .back-form {
        display: flex;
        width: 100%;
        position: relative;
        transition: all 0.2s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
        &:active {
            transform: scale(0.9);
        }
    }
    h1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: white;
    }
    p {
        font-size: 14px;
        font-weight: 600 !important;
        line-height: 20px;
        color: white;
    }
    .remove-btn-wrapper {
        display: flex;
        justify-content: space-between;
        font-weight: 700;
        color: white;
        button {
            color: white;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            width: 105px;
            height: 38px;
        }
        .go-back {
            width: 105px;
            height: 38px;
            border-radius: 5px;
            background-color: white;
            color: #f6655a;
        }
        .remove {
            width: 105px;
            height: 38px;
        }
    }
}
