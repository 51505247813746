.appendImg {
  top: 10px;
  height: 20px;
}

.search-bar-new {
  margin: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  font-size: 12px;
  font-weight: 600;
  border-radius: 0;
  border: none;
  color: unset;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  width: 140%;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

// Small Search Bar

.search-bar {
  font-size: 13px;
  border-radius: 5px;
  width: 100%;
  min-width: auto;
  height: 43px;
  padding-left: 35px;
  padding-right: 35px;
  border: none;
  box-sizing: border-box;
  font-weight: 900;
  color: black;
  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}

.prependImg {
  top: 50%;
  left: 10%;
  transform: translate(-50%, -50%);
}

.appendImgSmall {
  top: 50%;
  right: 1%;
  transform: translate(-50%, -50%);
}
