.video-uploader {
    position: relative;
    transition: all;
    height: 360px;
    min-height: 315px;
    max-height: 360px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 10px 30px 0px #00000026;
    overflow: hidden;
    margin-right: 20px;
    transition: all 0.5s ease-in-out;
    width: 202px;

    .video-upload-wrapper {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        z-index: 1;
        border-radius: 10px;
        & .video-upload-content {
            display: flex;
            justify-content: center;
            align-items: center;
            & .loader-animation {
                margin-top: 1px;
            }
            & .loader-animation svg {
                width: 12px;
                height: 12px;
            }
        }
        .video-upload-progressbar {
            margin-top: 8px;
            width: 130px;
            height: 4px;
            background: rgba(255, 255, 255, 0.5);
            position: relative;
            overflow: hidden;
            border-radius: 100px;
            .video-upload-progress {
                position: absolute;
                background-color: white;
                top: 0;
                left: 0;
                height: 4px;
            }
        }
    }

    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    .thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }
    .close-btn {
        position: absolute;
        height: 20px;
        width: 20px;
        top: 8px;
        right: 8px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        img {
            height: 10px;
            width: 10px;
        }
    }
    .aspect-ratio-btn {
        position: absolute;
        height: 17px;
        width: 19px;
        top: 10px;
        left: 10px;
        border: 1px solid rgba(255, 255, 255, 0.27);
        border-radius: 3px !important;
        color: #fff;
        font-weight: 700;
        font-size: 8px;
        z-index: 99;
        padding: 3px 0 3px 1px;
    }

    .video-uploader-title-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        background-color: white;
        bottom: 0;
        height: 44px;
        width: 100%;
        z-index: 2;
        left: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        box-sizing: border-box;
        img {
            width: 12px;
            margin-left: 4px;
            cursor: move;
            padding: 11px;
        }

        input {
            width: 70%;
            outline: none;
            font-size: 12px;
            box-shadow: none;
            background-color: transparent;
            color: #000;
            height: auto;
            border: unset;
            padding: 1px;
        }
    }
}

.video-upload-homepage {
    margin-top: 10px;
    height: 90px;
    border-radius: 6px;
    border: none !important;
    font-weight: bold;
    font-size: 10px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.5);
    position: relative;
    overflow: hidden !important;
    .video-upload-wrapper {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        z-index: 1;
        border-radius: 10px;
        & .video-upload-content {
            display: flex;
            justify-content: center;
            align-items: center;
            & .loader-animation {
                margin-top: 1px;
            }
            & .loader-animation svg {
                width: 12px;
                height: 12px;
            }
        }
        .video-upload-progressbar {
            margin-top: 8px;
            width: 100px;
            height: 4px;
            background: rgba(255, 255, 255, 0.5);
            position: relative;
            overflow: hidden;
            border-radius: 100px;
            .video-upload-progress {
                position: absolute;
                background-color: white;
                top: 0;
                left: 0;
                height: 4px;
            }
        }
    }
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
    }

    & .video-remove {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #ffffff;
        display: flex;
        align-items: center;
        img {
            width: 16px;
            height: 16px;
        }
    }
}
