.homepage {
    width: 100vw;
    height: 100vh;
    position: relative;
    margin: 0;
    padding: 0;
    // position: absolute;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    & .sign-in {
        display: flex;
        column-gap: 8px;
        position: absolute;
        top: calc(3px + 5%);
        right: 5%;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: rgba(255, 255, 255, 0.6);
        // color: #000000;
        z-index: 100000;
        & .btn-wrapper {
            width: 40px;
            height: 12px;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.88);
            // color: #000;
            & .btn {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                font-weight: bold;
                color: rgba(255, 255, 255, 0.88);
            }
        }
    }
}

.landing-container {
    width: 900px;
    height: 796px;
    max-height: 796px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(70px);
    border-radius: 8px;
    position: relative;
    box-sizing: border-box;
    padding-top: 85px;
    overflow: hidden;
    & .plan-details {
        width: 100%;
        padding: 0 64px;
        display: flex;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;

        & .current-plan {
            width: 425px;
            padding-right: 100px;
            box-sizing: border-box;
            & h1 {
                font-size: 24px;
                line-height: 28px;
                color: #000;
                font-weight: 700;
                margin: 0px;
            }
            & .tagline {
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                color: rgba(0, 0, 0, 0.5);
                margin-top: 16px;
            }
            & .btn-wrapper {
                width: 236px;
                height: 48px;
                margin-top: 28px;
                background: #000;
                // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
                border-radius: 6px;
                & .btn {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                    cursor: pointer;
                }
            }
            & .payment-info {
                margin-top: 9px;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                color: #4425f5;
            }
        }

        & .tier-sep {
            height: 100%;
            position: absolute;
            top: 50%;
            left: 48%;
            transform: translate(-150%, -50%);
        }
        & .plan-comparator {
            & h3 {
                margin: 0;
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                color: #000000;
            }
            & h2 {
                margin: 0;
                margin-top: 2px;
                font-size: 18px;
                line-height: 20px;
                color: #000000;
                margin-bottom: 30px;
            }

            & .slider-tier {
                margin-top: 40px;
            }

            & .current-tier {
                margin-top: 40px;
                height: 62px;
                & .price {
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 28px;
                    color: #000000;
                    & span {
                        font-size: 14px;
                        line-height: 16px;
                        color: #000000;
                    }
                }
                & .visitors {
                    margin-top: 2px;
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 600;
                    & span {
                        color: #4425f5;
                    }
                }
            }
        }
    }
    & .feature-wrapper {
        margin-top: 30px;
        height: 420px;
        min-height: 420px;
        max-height: 420px;
        padding: 20px 40px;
        position: relative;
        overflow: hidden;

        h1 {
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
        }
        .features-grid {
            width: 100%;
            height: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);

            .column-wrapper {
                display: flex;
                align-items: center;
            }
            img {
                margin: 10px;
            }
            h1 {
                margin: 0;
                font-size: 11px;
                line-height: 13px;
                font-weight: 700;
                text-align: center;
                opacity: 0.6;
            }

            // Row One
            .custom-cta {
                width: 120px;
                height: 120px;
                margin-left: 0px;
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 5px;
                overflow: hidden;
                .custom-cta-image {
                    width: 100%;
                    margin: 0;
                    margin-bottom: 5px;
                }
            }
            .space-analytics {
                width: 120px;
                height: 120px;
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                margin: 5px;
                margin-left: 10px;
                overflow: hidden;
                .space-analytics-image {
                    width: 70px;
                    margin: 0;
                    margin-top: 20px;
                }
            }
            .create-shareable-links {
                width: 260px;
                height: 120px;
                margin-left: 0px;
                margin: 5px;
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                .create-shareable-links-image {
                    width: 180px;
                    margin: 0;
                    margin-top: 10px;
                }
            }
            .curate-and-share {
                width: 260px;
                height: 123px;
                margin-left: 0px;
                margin: 5px;
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                overflow: hidden;
                .curate-and-share-image {
                    width: 100%;
                    margin: 0;
                    margin-top: 20px;
                }
            }

            // Row Two
            .quality-leads {
                width: 260px;
                height: 250px;
                margin-left: 0px;
                margin: 5px;
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                .quality-leads-image {
                    margin-top: 20px;
                    width: 80%;
                }
            }
            .api-access {
                width: 120px;
                height: 119px;
                margin-left: 0px;
                margin: 5px;
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                .api-access-image {
                    width: 40%;
                    margin-top: 10px;
                }
            }
            .dedicated-support {
                width: 120px;
                height: 119px;
                margin: 5px;
                margin-left: 10px;
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                .dedicated-support-image {
                    width: 30%;
                    margin-top: 10px;
                }
            }

            // Row three
            .embed-videos {
                width: 120px;
                height: 140px;
                margin: 5px;

                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                .embed-videos-image {
                    margin-bottom: 10px;
                    width: 50%;
                }
            }
            .dashboard-management {
                width: 120px;
                height: 140px;
                margin: 5px;
                margin-left: 10px;
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                .dashboard-management-image {
                    margin: 0;
                    width: 90%;
                }
            }
            .collaborative-teams {
                width: 260px;
                height: 100px;
                margin: 5px;
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                .collaborative-teams-image {
                    margin: 0;
                    width: 50%;
                    margin-bottom: 10px;
                }
            }
            .custom-branded-links {
                width: 260px;
                height: 116px;
                margin: 5px;
                margin-top: 10px;
                border: 1px solid rgba(255, 255, 255, 0.16);
                border-radius: 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                .custom-branded-links-image {
                    margin: 0;
                    width: 80%;
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.rc-slider {
    // width: 464px;
    height: 12px;
    box-sizing: border-box;
    & .rc-slider-rail {
      height: 8px;
    }
    & .rc-slider-track {
      height: 8px;
    }
    & .rc-slider-step {
      height: 8px;
    }
    & .rc-slider-dot {
        height: 8px !important;
    }
  }