.popups-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(17.5px);
    -webkit-backdrop-filter: blur(17.5px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    .step-wrapper {
        width: 900px;
        height: 796px;
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
        padding: 25px;
        border-radius: 6px;
        background: rgba(225, 225, 225, 0.9);
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 0 !important;
        overflow: hidden;
        position: relative;
        &::-webkit-scrollbar {
            width: 4px !important;
            display: block !important;
        }
        &::-webkit-scrollbar-thumb {
            width: 4px !important;
            border-radius: 10px;
            background-color: #adadad;
        }
        &::-webkit-scrollbar-track {
            background: #dcdcdc !important;
            width: 4px !important;
        }

        & .header {
            width: 100%;
            padding: 24px 24px 24px 24px;
            box-sizing: border-box;
            z-index: 2;
            backdrop-filter: blur(17.5px);
            position: relative;
            z-index: 9999999;
            height: 84px;
            & .back-button {
                & img {
                    width: 32px;
                    height: 32px;
                }
            }
            & .step-indicator {
                width: 100%;
                margin-right: 52px;
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: center;
                justify-self: center;
                z-index: 2;
                .circle {
                    height: 6px;
                    width: 6px;
                    background-color: black;
                    opacity: 0.12;
                    border-radius: 100%;
                    margin: 0px 5px;
                    transition: all 0.2s ease-in-out;
                    &.c-white {
                        background-color: #ffffff;
                        &.active {
                            background-color: #ffffff !important;
                        }
                    }
                }
                .active {
                    opacity: 1;
                    background-color: black;
                }
            }
        }
        .step-title {
            font-weight: 700;
            margin-top: 16px;
            font-size: 16px;
            line-height: 20px;
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            pointer-events: none;
        }
        .current-step-layout {
            margin-top: 0px !important;
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }

    & .bottom-step-wrapper {
        position: absolute;
        bottom: 0px;
        height: 112px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 32px;
        box-sizing: border-box;
        left: 0px;
        backdrop-filter: blur(17.5px);
        -webkit-backdrop-filter: blur(17.5px);
        background-color: #ffffff52;
        z-index: 999999;
        & .btn-container {
            width: 148px;
            height: 48px;
            margin-left: auto;
            & .custom-btn {
                margin-top: 0px;
                justify-self: flex-end;
                text-transform: uppercase;
            }
        }
    }
}
.component-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
    pointer-events: none;
    top: 60px;
    margin: 0;
}

.btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 25px;
    top: 25px;
    .button-edit {
        opacity: 0.7;
        border-radius: 6px;
        font-size: 10px;
        background-color: transparent;
        padding: 9px;
        color: rgba(0, 0, 0, 0.56);
        border-radius: 5px;
        border: 1px solid #000;
        font-weight: 700;
        text-transform: uppercase;
        font-family: 'Montserrat';
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
            margin-right: 5px;
            height: 12px;
            width: 12px;
        }
    }
}
.button-resend {
    background-color: black;
    padding: 15px 20px;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    font-weight: 700;
    text-transform: uppercase;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 43px;

    &:hover {
        box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
    }
}
