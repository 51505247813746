.traffic-bar-container {
    margin-top: 14px;
    width: 95%;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
    height: 100%;
    box-sizing: border-box;
    row-gap: 18px;
    .source {
        width: 100%;
        height: 40px;
        display: flex;
        column-gap: 12px;
        align-items: center;
        &:not(:first-child) {
            margin-top: 12px;
        }
        .icon {
            width: 35px;
            height: 32px;

            background: rgba(0, 0, 0, 0.05);
            border-radius: 100px;
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .details {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 40px;
            box-sizing: border-box;

            & h3 {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
                color: #000;
                margin: 0px;
                padding: 0px;
            }
            & .traffic-bar {
                width: 100%;
                display: flex;
                align-items: center;
                column-gap: 8px;
                & .progress {
                    height: 4px;
                    background: #000000;
                    border-radius: 100px;
                }
                & h3 {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 800;
                    font-size: 12px;
                    line-height: 14px;
                    color: #000000;
                }
            }
        }
    }
}

.stats-card-graph-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
