.space-field {
    width: 100%;
    margin-top: 30px;
    .optional-field {
        display: flex;
        align-items: center;
        width: 100%;
        .title {
            margin-left: 15px;
            font-size: 10px;
            font-weight: 700;
            line-height: 12px;
            cursor: pointer;
        }
    }
    .flat-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: -10px;
        .flat-inpt-wrapper {
            display: flex;
            & label {
                font-size: 10px;
                line-height: 12px;
            }
            input {
                min-width: 252px;
                width: 252px;
                padding-right: 45px !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .optional-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        background-color: rgba($color: #000000, $alpha: 0.2);
        transition: all 0.2s ease-in-out;
        &:hover {
            transform: scale(0.9);
        }
        img {
            width: 8px;
        }
    }

    // Price Container
    .price-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        .price-inpt-wrapper {
            display: flex;
            width: 310px;
            box-sizing: border-box;
            align-items: flex-end;
            & label {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }
    .select-input {
        width: 150px !important;
        select {
            height: 43px !important;
            width: 88px;
            margin: 5px 0px;
            padding: 10px;
            border-radius: 6px;
            min-width: auto;
            border: none;
            font-size: 12px !important;
            font-weight: 900;
            color: black;
            -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            outline: none;
            option {
                height: 40px;
                border: 0px;
                margin: 5px 0px;
                padding: 10px;
            }
        }
    }

    &:last-child {
        margin-bottom: 10px;
    }
}

.rental-selection {
    position: absolute;
    right: 100px;
    width: 142px;
    height: 45px;
    & .custom-select-container img {
        position: absolute;
        right: 16px;
    }
}
.custom-price {
    // margin-top: 4px;
    & input {
        height: 44px !important;
        width: 200px !important;
    }
}
