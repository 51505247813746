.ht-container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    .header-text {
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #000000;
    }
    .tag-text {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.5);
    }
}

.shimmer-effect {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.072) 0%, rgba(0, 0, 0, 0) 72.27%), rgba(0, 0, 0, 0.04);
    animation-duration: 15s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    border-radius: 4px;
}
.shimmer-effect.header {
    width: 110px !important;
    height: 12px !important;
    padding: 0 !important;
    margin-bottom: 12px;
}

.shimmer-effect.tag {
    width: 110px !important;
    height: 12px !important;
    padding: 0 !important;
}
@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}
