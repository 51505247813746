.map-container {
  width: 900px;
  min-width: 800px;
  max-width: 950px;
  min-height: 730px;
  height: 796px;
  max-height: 796px;
  z-index: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
  border-radius: 6px;


  & .map-wrapper {
    width: 100%;
    height: 100%;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
    border-radius: 6px !important;
    position: relative;
    box-sizing: border-box;
    & .nav-controls {
      position: absolute !important;
      top: 24px !important;
      right: 24px !important;
    }
    & .mapboxgl-ctrl-logo {
      display: none;
    }

    & .mapboxgl-ctrl-top-right {
      width: 36px;
      height: 72px;
      top: 24px;
      right: 24px;
    }

    & .mapboxgl-ctrl.mapboxgl-ctrl-group {
      width: 36px;
      height: 72px;
      box-sizing: border-box;
      margin: 0;
      position: absolute;

      & .mapboxgl-ctrl-zoom-in {
        width: 36px;
        height: 36px;
        margin: 0px;
      }
      & .mapboxgl-ctrl-zoom-out {
        width: 36px;
        height: 36px;
        margin: 0px;
      }
      & .mapboxgl-ctrl-compass {
        display: none;
      }
    }

    // error on search styles
    & .error-box {
      width: 852px;
      height: 46px;
      background: #F6655A;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      position: absolute;
      left: 24px;
      bottom: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      color: #ffffff;
      & img {
        position: absolute;
        top: 11px;
        left: 10px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    // search results box
    & .search-results {
      width: 852px;
      position: absolute;
      left: 24px;
      bottom: 100px;
      z-index: 2;
      background-color: #fff;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      max-height: 200px;
      overflow-y: scroll;
      & ul {
        padding: 4px 0;
        box-sizing: border-box;
      }
      & ul li {
        height: 48px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        padding-left: 64px;
        font-family: Montserrat;
        position: relative;
        &:last-child {
          border-bottom: none;
        }
        &:hover{
          color: #000000;
          & img:first-child {
            display: none;
          }
          & img:nth-child(2) {
            display: block;
          }
        }

        & img {
          width: 24px;
          height: 24px;
          margin-right: 20px;
          position: absolute;
          top: 50%;
          left: 24px;
          transform: translateY(-50%);
        }
        & img:first-child {
          display: block;
        }
        & img:nth-child(2) {
          display: none;
        }
      }
    }
    // search box styles
    & .map-search-box {
      position: absolute;
      width: 850px;
      height: 64px;
      left: 24px;
      bottom: 24px;
      background: #FFFFFF;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
      border-radius: 12px;
      z-index: 1;
      display: flex;
      align-items: center;
      padding: 0 12px 0 24px;
      box-sizing: border-box;
      & .shimmer-bg {
        width: 668px;
        height: 20px;
        animation-duration: 4.2s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: shimmer;
        animation-timing-function: linear;
        background: #ddd;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 72.27%), #EDEDED;
        background-size: 668px 20px;
      }
      & .input-wrapper {
        box-sizing: border-box;
        width: 676px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & input {
          font-weight: 700;
          font-size: 14px !important;
          line-height: 20px;
          color: #000000;
          box-shadow: none;
          padding: 0;
          margin: 0;
        }
        & .clear-text {
          width: 19px;
          height: 19px;
          cursor: pointer;
          & img {
            width: 19px;
            height: 19px;
          }
        }
      }
      & .search-map {
        width: 116px;
        height: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        position: absolute;
        right: 12px;
        bottom: 10px;
      }
    }
  }
}


// map marker styles
.map-marker {
  z-index: 999999999999999999;
  & .dot {
    width: 6px;
    height: 6px;
    background: #ffffff;
    border-radius: 50%;
    position: relative;
    display: flex;
    column-gap: 10px;

    & .tooltip {
      width: 220px;
      max-width: 220px;
      height: 44px;
      max-height: 44px;
      box-sizing: border-box;
      position: absolute;
      top: -52px;
      left: -13px;
      display: flex;
      column-gap: 12px;
      transition: all 0.2s ease-in-out;

      & .map-marker__box {
        width: 32px;
        height: 44px;
        border-radius: 6px;
        background: #ffffff;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          width: 0; 
          height: 0; 
          top: 44px;
          left: 50%;
          transform: translateX(-50%);
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #fff;
        }
      }

      & .marker-use {
        width: 178px;
        box-sizing: border-box;
        max-width: 178px;
        height: 44px;
        font-size: 12px;
        line-height: 12px;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #4425F5;
        cursor: pointer;
        border-radius: 6px;
        padding: 0 14px;
        transition: all 0.2s ease-in-out;
        position: absolute;
        left: 44px;    
        &.search{
          width: 44px;
          padding: 8px;
          & button {
            width: 30px !important;
          }
        }
        
        &:hover {
          box-shadow: none;
        }
        & button {
          margin: 0;
          height: 100%;
          width: 122px;
          text-transform: uppercase;
          background-color: #4425F5;
          &:hover {
            box-shadow: none !important;
          }
        }
        & img {
          width: 13px;
          height: 13px;
        }
      }
    }
    
  }
}


// mapbox custom styles
.mapboxgl-marker {
  opacity: 1 !important;
}


.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}  


@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}