.edit-contact-wrapper-cta {
    width: 900px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 100%;
    box-sizing: border-box;
    // padding: 0 20px;
    padding: 120px 176px;

    &::-webkit-scrollbar {
        width: 4px !important;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 10px;
        background-color: #adadad;
    }
    &::-webkit-scrollbar-track {
        background: #dcdcdc !important;
        width: 4px !important;
    }
    & .agent-avatar {
        & > .avatar-div {
            display: flex;
            column-gap: 16px;
            & .avatar-img {
                width: 56px;
                height: 56px;
            }
            & .assigne__name {
                left: 0px;
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
            }
        }
        & .assigne__avatar__name {
            font-weight: bold;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            position: relative;
            left: 16px;
        }
    }

    & .agent-details {
        width: 100%;

        & .agent-field {
            width: 100%;
            margin-top: 30px;
            &:first-child {
                margin-top: 0px;
            }
            &:last-child {
                margin-bottom: 20px;
            }
            & .optional-field {
                display: flex;
                align-items: center;
                width: 100%;
                .title {
                    margin-left: 15px;
                    font-size: 10px;
                    font-weight: 700;
                    line-height: 12px;
                    cursor: pointer;
                }
            }
            & .optional-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                border-radius: 100%;
                background-color: rgba($color: #000000, $alpha: 0.2);
                transition: all 0.2s ease-in-out;
                &:hover {
                    transform: scale(0.9);
                }
                img {
                    width: 8px;
                }
            }

            & .input-container {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: -10px;
                .flat-inpt-wrapper {
                    display: flex;
                    position: relative;
                    .cta-phone-img {
                        position: absolute;
                        height: 24px;
                        right: 28px;
                        top: 27px;
                        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
                        border-radius: 50%;
                    }
                    & label {
                        font-size: 10px;
                        line-height: 12px;
                    }
                    input {
                        min-width: 252px;
                        width: 252px;
                        padding-right: 45px !important;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    & .mobile-input-btn.flag-dropdown {
                        box-sizing: border-box;
                        width: 64px;
                        height: 44px !important;
                    }
                    & .mobile-input-main {
                        margin-left: 70px !important;
                        height: 44px !important;
                    }
                    & .msg-text-inp {
                        width: 504px;
                        height: 94px;
                        margin-bottom: 5px;
                    }
                    & textarea {
                        max-width: 100%;
                        resize: none;
                        padding: 14px 16px;
                        border-radius: 5px;
                        font-size: 12px !important;
                        font-weight: 900;
                        border: none;
                        box-sizing: border-box;
                        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                        outline: none;
                    }
                    & .character-count {
                        position: absolute;
                        right: 0px;
                        bottom: -15px;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        color: rgba(0, 0, 0, 0.24);
                    }
                }
            }
        }
        & .agent-avatar {
            & > .avatar-div {
                display: flex;
                column-gap: 16px;
                & .avatar-img {
                    width: 56px;
                    height: 56px;
                }
                & .assigne__name {
                    left: 0px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 20px;
                    color: #000000;
                }
            }
            & .assigne__avatar__name {
                font-weight: bold;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
                position: relative;
                left: 16px;
            }
        }
    }

    & .line-break {
        width: 100%;
        border: 1px solid rgba(255, 255, 255, 0.32);
        margin: 5px 0 30px 0px;
        position: relative;
    }
    & .line-break::after {
        content: '';
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.08);
        margin: 32px 0 25px 0px;
        position: absolute;
        top: -31px;
        left: -1px;
    }
}

.custom-btn-input {
    min-width: 196px !important;
}

.type-form-note {
    height: 56px;
    background: #f3f3f3;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    padding-left: 12px;
    width: 504px;
    box-sizing: border-box;
}

.agent-custom-button-input {
    column-gap: 10px;
}
.save-template {
    width: auto !important;
}

.delete-template {
    position: absolute;
    left: 30px !important;
    top: 50%;
    transform: translateY(-50%);
    color: #e00000 !important;
    background-color: transparent !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
}
