.manage-agent-wrapper {
    width: 900px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 560px;
    height: 550px !important;
    box-sizing: border-box;
    // padding: 0 20px;
    margin-top: 40px;
    padding: 0 150px;
    &::-webkit-scrollbar {
        width: 4px !important;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 10px;
        background-color: #adadad;
    }
    &::-webkit-scrollbar-track {
        background: #dcdcdc !important;
        width: 4px !important;
    }
    & .assigned-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        .assigned-profile {
            display: flex;
            align-items: center;
            p {
                margin-left: 5px;
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
            }
        }
        label {
            font-size: 10px;
            line-height: 12px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 5px;
        }
        .assigned-wrapper {
            width: 100%;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: white;
            border-radius: 6px;
            -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);

            .assigned-profile-btn {
                text-decoration: underline;
                font-size: 10px;
                line-height: 12px;
                font-weight: 700;
                margin-right: 10px;
                opacity: 0.5;
            }
        }
        // Assigned Seach
        .assigned-search-container {
            width: 100%;
            background-color: white;
            margin-top: 5px;
            border-radius: 6px;
            position: absolute;
            top: 60px;
            .assigned-search-wrapper {
                background-color: white;
                margin-left: 5px;
                border-radius: 6px;
                padding: 0px 5px;
                font-size: 12px;
                font-weight: 600;
                display: flex;
                flex-direction: column;
                align-items: center;

                .search-input-wrapper {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    img {
                        width: 10px;
                        height: 10px;
                    }
                }
                hr {
                    margin: 0;
                    width: 100%;
                    height: 1px;
                    background-color: rgba(0, 0, 0, 0.1);
                    border: none;
                }
            }

            .assigned-search-results {
                overflow-y: scroll;
                max-height: 160px;
                .assigned-profile {
                    margin-top: 10px;
                }
                .loading-assignee {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    .loading-assignee-circle {
                        width: 24px;
                        height: 24px;
                        border-radius: 100%;
                    }
                    .loading-assignee-rect {
                        width: 100%;
                        margin-left: 10px;
                        height: 14px;
                        border-radius: 100px;
                    }
                }
            }
        }
        img {
            position: absolute;
            right: 16px;
            top: 35px;
        }
    }

    & .template-listings {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        margin-top: 32px;
        label {
            font-size: 10px;
            line-height: 12px;
            font-weight: 700;
            text-align: left;
            margin-bottom: 5px;
        }
    }
    & .agents-info {
        margin-top: 64px;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 6px;
        padding: 24px;
        box-sizing: border-box;
        margin-bottom: 20px;
        & .title {
            font-weight: bold;
            font-size: 16px;
            line-height: 18px;
            color: #000000;
        }
        & .agents-note {
            height: 56px;
            background: #f3f3f3;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 10px;
            line-height: 14px;
            color: rgba(0, 0, 0, 0.5);
            margin-bottom: 20px;
            padding-left: 12px;
            & img {
                margin-right: 10px;
            }
        }
        & .available-contacts {
            margin-top: 32px;
            & .sub-title {
                font-weight: bold;
                font-size: 10px;
                line-height: 12px;
                color: #000000;
                text-transform: uppercase;
            }
            & .available-contacts-cta {
                margin-top: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                & .contacts-list {
                    background: #ffffff;
                    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                    border-radius: 1000px;
                    padding: 8px 12px;
                    display: flex;
                }
                & .edit-contacts {
                    font-weight: bold;
                    font-size: 10px;
                    line-height: 12px;
                    text-align: right;
                    text-decoration-line: underline;
                    text-transform: uppercase;
                    color: rgba(0, 0, 0, 0.5);
                    cursor: pointer;
                }
            }
        }
        & .agents-msg {
            margin-top: 32px;
            & .welcome-msg-title {
                font-weight: bold;
                font-size: 10px;
                line-height: 12px;
                text-transform: uppercase;
                color: #000000;
            }
            & .msg {
                margin-top: 12px;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                // font-style: normal;
                color: rgba(0, 0, 0, 0.5);
                white-space: pre-wrap;
                word-wrap: break-word;
            }
        }
    }
}
.bottom-wrapper {
    button {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #000;
        border: none;
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        height: 40px;
        border-radius: 6px;
    }
}
