.cta-box {
    // width: 24px;
    height: 24px;
    display: flex;
    column-gap: 8px;
    & .cta {
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        overflow: visible;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        & img {
            width: 24px;
            height: 24px;
        }
        &.phone {
            background: #ffffff;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        }
        &.wp {
            background: linear-gradient(226.97deg, #5bd066 9.27%, #27b43e 100%);
        }
        &.email {
            background: #ffffff;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        }
        &.custom-link {
            background: #ffffff;
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
        }
        &.typeform {
            background: #ffffff;
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
        }
        &.telegram {
            background: #0088cc;
        }
        &.fbmessenger {
            background: linear-gradient(189.16deg, #00b1ff 6.95%, #006bff 94.81%);
        }
        &.cal {
            background: #ffffff;
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
            & img {
                border-radius: 50% !important;
            }
        }
        & .cta_tooltip {
            position: absolute;
            top: -40px;
            left: 50%;
            transform: translateX(-50%);
            padding: 10px 12px;
            background: #ffffff;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            color: #000000;
            display: none;
            &::before {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                top: 32px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid #fff;
            }
        }

        &:hover {
            & .cta_tooltip {
                display: flex;
                justify-content: center;
            }
        }
    }
}

.cta-single-container {
    display: flex;
    column-gap: 8px;
    & .single-cta {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 8px;
        cursor: pointer;
        overflow: visible;
        & img {
            width: 24px;
            height: 24px;
        }
        & .cta_info {
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.5);
        }

        &.phone {
            background: #ffffff;
            // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        }
        &.wp {
            background: linear-gradient(226.97deg, #5bd066 9.27%, #27b43e 100%);
        }
        &.email {
            background: #ffffff;
            // box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
        }
        &.custom-link {
            background: #ffffff;
            // box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
        }
        &.typeform {
            background: #ffffff;
            // box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
        }
        &.telegram {
            background: #0088cc;
        }
        &.fbmessenger {
            background: linear-gradient(189.16deg, #00b1ff 6.95%, #006bff 94.81%);
        }
        &.cal {
            background: #ffffff;
            // box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
            & img {
                border-radius: 50% !important;
            }
        }
    }
}
