.more-container {
    display: flex;
    align-items: center;
    column-gap: 16px;
    width: 100px;
    &__btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        & img {
            width: 8px;
        }
    }
    &__title {
        cursor: pointer;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #000000;
    }
}
