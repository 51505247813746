@import url('https://fonts.googleapis.com/css?family=Roboto');
body {
    background: #f3f3f3;
    margin: 0;
    padding: 0;
}

body,
input,
button {
    font-family: 'Montserrat' !important;
}

button {
    border: none;
    background: transparent;
    cursor: pointer;
}
ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

input {
    border-radius: 5px;
    height: 40px;
    width: 100%;
    min-width: auto;
    padding: 16px 12px;
    border: none;
    box-sizing: border-box;
    font-size: 12px !important;
    font-weight: 900;
    color: black;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    outline: none;
}
::-webkit-input-placeholder {
    /* Edge */
    color: #c9c9c9;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c9c9c9;
}

::placeholder {
    color: #c9c9c9;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

a {
    text-decoration: none;
}

.dummyElement {
    width: 80px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar {
    display: none;
}

.body-no-scroll {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.body-no-scroll::-webkit-scrollbar {
    display: none;
}

// Form
.form-logo {
    margin: 50px 0px;
    transform: scale(0.7);
}

.custom-blur {
    backdrop-filter: blur(6.5px);
    -webkit-backdrop-filter: blur(6.5px);
}

.form-card-wrapper {
    width: 230px;
    padding: 30px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(225, 225, 225, 0.9);
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
    position: relative;

    @include media('<phone') {
        width: 230px;
    }

    @include media('>=phone', '<lgphone') {
        width: 230px;
    }
    @include media('>=lgphone', '<tablet') {
        width: 230px;
    }
    .form-wrapper {
        width: 100%;
        height: 100%;
        h1 {
            font-size: 18px;
            line-height: 20px;
            font-weight: 700;
        }
        .input-wrapper {
            width: 100%;
        }
    }
    .back-form {
        display: flex;
        width: 100%;
        position: relative;
        transition: all 0.2s ease-in-out;
        &:hover {
            transform: scale(1.1);
        }
        &:active {
            transform: scale(0.9);
        }
    }
    .back-button {
        img {
            transition: all 0.2s ease-in-out;
            outline: none;
            background: transparent;
            border: 0px;
            &:hover {
                transform: scale(1.1) translateX(-2px);
                background: transparent;
            }
            &:active {
                transform: scale(0.9);
                background: transparent;
            }
        }
    }
}

.desktop-view {
    @include media('>lgphone') {
        display: block;
    }
    @include media('<=lgphone') {
        display: none;
    }
}

.mobile-view {
    @include media('>lgphone') {
        display: none;
    }
    @include media('<=lgphone') {
        display: block;
    }
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
    border-radius: 5px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
    border-radius: 5px !important;
}

.shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #ddd;
    background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    background-size: 1200px 100%;
}

@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}

//toast custom css
.Toastify__toast--error {
    background: #f6655a !important;
    box-shadow: 0px 10px 30px rgba(255, 0, 0, 0.25) !important;
}
.Toastify__toast--success {
    background: rgba(0, 0, 0, 0.72) !important;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.25) !important;
}
.Toastify__toast-container {
    width: auto !important;
}

.Toastify__toast {
    border-radius: 8px !important;
    min-height: 0px !important;
    font-family: 'Montserrat' !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 8px 18px !important;
}

.Toastify__close-button {
    display: none !important;
}

// Blocker Screen
.blocker-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    cursor: none;
    background: black;
    opacity: 0.1;
}
@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        left: 50% !important;
        transform: translateX(-50%) !important;
    }
}
