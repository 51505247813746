.card-shadow {
    box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.2);
}
.propCard {
    width: 300px;
    height: 500px;
    background-size: cover;
}
.fullAddress {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    min-width: 120px;
}
.backDrop {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    height: 98%;
}
.onTheMarket {
    background-color: rgba(0, 0, 0, 0.45);
}
.underOffer {
    background-color: rgba(0, 71, 255, 0.45);
}
.letAgreed {
    background-color: rgba(0, 191, 77, 0.45);
}
.movedIn {
    background-color: rgba(255, 255, 255, 0.7);
}

// New Styles

.prop-card {
    box-sizing: border-box;
    width: 100%;
    height: 130px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.32);
    &:nth-child(even) {
        background: rgba(255, 255, 255, 0.02);
    }

    &:nth-child(odd):hover {
        background-color: rgba(255, 255, 255, 0.7);
    }
    &:nth-child(even):hover {
        background-color: rgba(255, 255, 255, 0.5);
    }
    &-wrapper {
        display: grid;
        grid-template-columns: 14fr 4fr 4fr 4fr 10fr 40px;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }
    .heading-wrapper {
        display: flex;
        align-items: center;
        margin-left: 20px;
        a {
            height: 100px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.28);
            border-radius: 8px;
        }
        .heading-wrapper-img {
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.28);
            height: 100px;
            border-radius: 8px;
        }
        img.thumbnail {
            height: 100%;
            min-width: 64px;
            max-width: 64px;
            background-color: #000000;
            border-radius: 8px;
            overflow: hidden;
            object-fit: cover;
            transition: all 0.2s ease-in-out;
            box-sizing: border-box;
        }
        &-content {
            margin-left: 30px;
            height: 100%;
            padding-right: 48px;
            & .heading-status {
                display: flex;
                column-gap: 4px;
                align-items: center;
            }
            & .private-status {
                width: 18px;
                height: 18px;
                background-color: rgba(0, 0, 0, 0.08) !important;
                position: relative;
                padding: 0px;
                border-radius: 4px !important;
                & img {
                    width: 18px !important;
                    min-width: 18px !important;
                    height: 18px !important;
                    min-height: 18px !important;
                    object-fit: contain;
                    background-color: rgba(0, 0, 0, 0.08) !important;
                    position: absolute;
                    border-radius: 4px !important;
                }
            }
            .heading-title {
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
                padding: 3px 6px;
                border-radius: 4px;
            }
            .heading-title-pub {
                color: #000;
                background-color: rgba(0, 0, 0, 0.08);
            }
            .heading-title-un {
                color: #e00000;
                background-color: #ff000014;
            }
            .heading {
                font-size: 10px;
                font-weight: 600;
                word-break: break-word;
                margin: 14px 0 12px 0;
            }
            .space-details-bed-bath {
                margin-top: 10px;
                display: flex;
                align-items: center;

                .space-comp {
                    img {
                        margin-left: 0px;
                        margin-right: 2px;
                        height: 15px;
                        width: auto;
                    }
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                    span.text {
                        color: #00000080;
                        font-size: 10px;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .sale-rent-wrapper {
        max-height: 30px;
        div {
            font-size: 10px;
            text-transform: uppercase;
            font-weight: bold;
            border-radius: 4px;
            padding: 10px;
            display: inline-block;
            color: rgba(0, 0, 0, 0.5);
        }
        .sale {
            background: #ffffff;
        }
        .rent {
            background: rgba(0, 0, 0, 0.09);
        }
    }

    .hashtags-wrapper {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .hashtags-title {
            font-size: 8px;
            font-weight: 700;
            opacity: 0.2;
            position: absolute;
            top: 10%;
        }
        .hash {
            font-weight: 700;
            font-size: 12px;
            margin: 5px;
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: scale(1.1);
            }
            &:active {
                transform: scale(0.9);
            }
        }
        .more-hashbtn {
            display: flex;
            margin: 10px;
            background-color: #dddddd;
            padding: 5px;
            border-radius: 20px;
            width: 20px;
            text-align: center;
            font-size: 10px;
            color: white;
            transition: all 0.2s ease-in-out;
            &:hover {
                transform: scale(1.1);
            }
            &:active {
                transform: scale(0.9);
            }
        }
    }
    .price-wrapper {
        font-size: 12px;
        font-weight: bold;
        span.no-price {
            color: #00000033;
            font-size: 12px;
            font-weight: 700;
        }
    }
    .cta-wrapper {
        max-height: 30px;
        .paired {
            background: #c9ffc4;
        }
        .not-paired {
            background: #ffffff;
        }
        span {
            border-radius: 5px;
            font-weight: 700;
            font-size: 10px;
            color: rgba(0, 0, 0, 0.5);
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    .managed-btn {
        div:not(:last-child) {
            margin-right: 10px;
        }
        display: flex;
        &-wrapper {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
            height: 32px;
            width: auto;
            border-radius: 18px;
            display: flex;
            align-items: center;
            padding: 1px 5px;
            &-img {
                height: 24px;
                width: 24px;
                min-width: 24px;
                background-color: #000;
                border-radius: 50%;
                margin-right: 8px;
                overflow: hidden;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .avatar-name {
                margin-right: 8px;
                font-weight: 700;
                font-size: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                max-width: 160px;
            }
        }
        &-box {
            position: relative;
            height: 34px;
            width: 34px;
            min-width: 34px;
            border-radius: 50%;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
            &-img {
                height: 16px;
            }
            .tooltip {
                position: absolute;
                top: -40px;
                left: 50%;
                transform: translateX(-50%);
                padding: 10px 12px;
                background: #ffffff;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                font-weight: bold;
                font-size: 10px;
                line-height: 12px;
                color: #000000;
                display: none;
                &::before {
                    content: '';
                    position: absolute;
                    width: 0;
                    height: 0;
                    top: 32px;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid #fff;
                }
            }
            &:hover {
                & .tooltip {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    .setting-btn {
        .menu {
            position: absolute;
            background-color: white;
            width: 214px;
            height: 104px;
            border-radius: 12px;
            z-index: 100;
            box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            right: 3px;
            top: 3px;
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                font-size: 11px;
                font-weight: 700;
                margin-left: 20px;
                img {
                    width: 19px;
                    height: 19px;
                }
            }
            .close-btn {
                position: absolute;
                right: 2px;
                top: 2px;

                img {
                    width: 35px;
                    height: 35px;
                }
            }
        }
    }
}

.products-custom-checkbox {
    z-index: 2;
    position: relative;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    border: 2px solid #c0c0c0;
    background-color: #fff;
    cursor: pointer;
    padding: 2px;
    div {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        background-color: #001aff;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.managed-btn-wrapper > div > div > div > div > span > span {
    font-size: 10px !important;
    font-family: Montserrat;
}
