// Publish Space
.publish-space-container {
    width: 100%;
    box-sizing: border-box;
    margin-top: 24px;
    overflow-y: scroll;
    height: 566px;
    .publish-space-wrapper {
        width: 100%;
        height: 566px;
        max-height: 566px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        box-sizing: border-box;
        // padding: 32px 0px;
        &::-webkit-scrollbar {
            width: 4px !important;
            display: block !important;
        }
        &::-webkit-scrollbar-thumb {
            width: 4px !important;
            border-radius: 10px;
            background-color: #adadad;
        }
        &::-webkit-scrollbar-track {
            background: #dcdcdc !important;
            width: 4px !important;
        }

        & .demo {
            position: relative;
            box-sizing: border-box;
            // & img{
            //   width: 100%;
            //   height: 100%;
            // }
            & .widget {
                border-radius: 8px;
                width: 64px;
                height: 88px;
                background: #000000;
                box-shadow: 0px 20.0769px 33.4615px rgba(0, 0, 0, 0.3);
                position: absolute;
                bottom: 30px;
                left: -40px;
                & .poster {
                    width: 64px;
                    height: 88px;
                    border-radius: 8px;
                    position: relative;
                    box-sizing: border-box;
                    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.4);
                    & img {
                        width: 100%;
                        height: 100%;
                        border-radius: 8px;
                        object-fit: cover;
                    }
                    & .poster-close {
                        position: absolute;
                        top: 4px;
                        right: 4px;
                        width: 12px;
                        height: 12px;
                        cursor: pointer;
                    }
                    & .video-tour {
                        width: 36px;
                        height: 8px;
                        box-sizing: border-box;
                        position: absolute;
                        top: 6px;
                        left: 4px;
                        background-color: rgba(255, 255, 255, 0.7);
                        display: flex;
                        align-items: center;
                        padding-left: 2px;
                        border-radius: 1px;
                        & .green-cirlce {
                            min-width: 3px;
                            min-height: 3px;
                            width: 3px;
                            height: 3px;
                            background-color: #ff0000;
                            border-radius: 50%;
                            margin-right: 3px;
                            animation: blinker 1.5s linear infinite;
                        }
                        & .text {
                            font-size: 3.5px;
                            line-height: 8px;
                            font-weight: bold;
                            color: #fff;
                            text-transform: uppercase;
                        }
                    }
                    & .view-count {
                        height: 10px;
                        position: absolute;
                        bottom: 4px;
                        right: 6px;
                        display: flex;
                        align-items: center;
                        & img {
                            width: 10px;
                            height: 10px;
                            margin-right: 2px;
                        }
                        & .count {
                            font-weight: bold;
                            font-size: 6px;
                            line-height: 100%;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}

.body-ovef-hdn {
    overflow: hidden;
}

.popups-wrapper {
    & .toast-message-wrapper {
        z-index: 999999;
    }
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.bottom-wrapper {
    button {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #000;
        border: none;
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        height: 40px;
        border-radius: 6px;
    }
}

.link-container {
    // margin-top: 40px;
    width: 548px;
    box-sizing: border-box;
    &.flex-col {
        display: flex;
        flex-direction: column;
    }
    & .header {
        display: flex;
        justify-content: space-between;
    }
    & .title {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #000000;
    }
    & .subtitle {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.5);
        margin-top: 10px;

        & span {
            font-weight: 700;
            color: #000;
        }
    }

    & .space-card {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        & .current-space {
            width: 90px;
            height: 162px;
            position: relative;
            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 1;
            }
            & .overalay {
                background: rgba(255, 255, 255, 0.08);
                box-shadow: 0px -8.77839px 26.3352px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(7.90056px);
                border-radius: 6px;
                position: absolute;
                bottom: 0px;
                left: 0px;
                height: 120px;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                box-sizing: border-box;
                & img {
                    width: 20px;
                    height: 20px;
                }
                & .email-container {
                    display: flex;
                    flex-direction: column;
                    row-gap: 0px;
                    & label {
                        font-weight: 600;
                        font-size: 5.07873px;
                        line-height: 120%;
                        color: rgba(255, 255, 255, 0.64);
                    }
                    & input {
                        margin-top: 4px;
                        background: rgba(255, 255, 255, 0.04);
                        border: 1px solid #ffffff;
                        box-sizing: border-box;
                        font-weight: 700;
                        font-size: 4px !important;
                        line-height: 7px;
                        color: rgba(255, 255, 255, 0.3);
                        height: 17px;
                        box-sizing: border-box;
                        padding: 5px;
                        border-radius: 3px !important;
                    }
                }
            }
        }
        & img {
            height: 115px;
            width: 66px;
            border-radius: 6px;
            opacity: 0.5;
        }
    }

    & .note {
        margin-top: 40px;
        height: 56px;
        // background: #f3f3f3;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.5);
        margin-bottom: 20px;
        // padding-left: 12px;
        & img {
            margin-right: 10px;
        }
    }
    & .input-wrapper {
        margin-top: 25px;
        // width: 548px;
        display: flex;
        align-items: center;
        position: relative;
        & input {
            padding-right: 40px;
            height: 44px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        & input::placeholder {
            color: #000;
        }
        & .custom-placeholder::placeholder {
            color: #979797;
        }

        button {
            width: 16px;
            height: 16px;
            position: absolute;
            right: 10px;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .copy-btn {
            position: absolute;
            right: 10px;
            font-size: 10px;
            font-weight: 700;
            line-height: 12px;
            width: 54px;
            height: 32px;
            border-radius: 6px;
            border: 1px solid black;
            opacity: 0.7;
            box-sizing: border-box;
            transition: all 0.2s ease-in-out;
            &:hover {
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
            }
            &:active {
                transform: scale(0.98);
            }
            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    & .direct-link {
        & input {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 65px;
            background-color: #00000014;
            box-shadow: none;
            border: 1px solid rgba(0, 0, 0, 0.06);
        }
        & input::placeholder {
            color: #000;
        }
    }
    & .magiclink-status {
        margin-top: 16px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #4425f5;
    }

    & .webpage-input-container-error {
        margin-top: 16px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #e00000;
    }

    & .magiclink-search-loading {
        margin-top: 8px;
        width: 548px;
        background: rgba(255, 255, 255, 0.16);
        border: 1px solid rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        border-radius: 6px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        color: rgba(0, 0, 0, 0.5);
        column-gap: 12px;
        & span {
        }
    }

    & .warning-wrapper {
        background: #ffcf88;
        border-radius: 8px;
        display: flex;
        align-items: center;
        margin-top: 8px;
        padding: 16px 18px 16px 26px;
        box-sizing: border-box;
        &.paired-error-wrapper {
            background: #ff8888 !important;
            & .error-text {
                max-width: 464px !important;
            }
        }
        & .warning-text {
            margin: 0px 16px 0px 14px;
            box-sizing: border-box;
            max-width: 356px;
            & .title {
                margin: 0;
                font-weight: bold;
                font-size: 10px;
                line-height: 12px;
                color: rgba(0, 0, 0, 0.5);
            }
            & .subtitle {
                font-weight: 600;
                font-size: 10px;
                line-height: 14px;
                margin: 4px 0 0 0;
                color: rgba(0, 0, 0, 0.32);
            }
        }

        & .warning-btn {
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            // text-align: center;
            text-decoration-line: underline;
            text-transform: uppercase;
            cursor: pointer;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    & .success-pair {
        background: #c9ffc4;
        border-radius: 8px;
        box-sizing: border-box;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 16px;
        margin-top: 8px;
        & .msg {
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            color: rgba(0, 0, 0, 0.5);
            display: flex;
            align-items: center;
            column-gap: 5px;
        }
        & .view-btn {
            font-weight: bold;
            font-size: 10px;
            line-height: 12px;
            text-decoration-line: underline;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
        }
    }
}

.link-container-br {
    border: 1px solid rgba(255, 255, 255, 0.32);
    // width: 548px;
    margin-top: 36px;
}
