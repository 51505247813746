.dropdown-container {
    display: flex;
    background: #ffffff;
    border: 1px solid #e6e6e6;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    border-radius: 6px;
    max-height: 44px;
    height: 44px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    cursor: pointer;
    position: relative;
    &-selected {
        font-weight: 700;
        font-size: 12px;
        color: #000;
        min-width: 160px;
    }
    &-options {
        z-index: 2;
        top: 44px;
        left: 0;
        position: absolute;
        width: 100%;
        background: #ffffff;
        border: 1px solid #e6e6e6;
        box-sizing: border-box;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        padding: 5px 0;
        max-height: 220px;
        overflow-y: scroll;
        &-items {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px 18px;
            transition: background-color 200ms ease;
            &-name {
                font-weight: 700;
                font-size: 12px;
                color: #000;
            }
            &:hover {
                background: #ececec;
            }
        }
    }
}
