.team-settings-container {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 200px;
    h4.team-heading {
        font-weight: bold;
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 30px;
    }
    .team-control-container {
        width: 35%;
        margin-right: 20px;
        .active {
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
            background: rgba(255, 255, 255, 0.8);
            transition: all 0.2s ease;
        }
        &-wrapper {
            border-radius: 6px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 18px 20px 20px;
            margin-bottom: 20px;
            &-content {
                h4 {
                    margin: 0;
                    font-weight: bold;
                    font-size: 10px;
                    text-transform: uppercase;
                    border-radius: 2.5px;
                    padding: 5px 4px;
                    background-color: #999999;
                    color: #fff;
                    display: inline-block;
                }
                p {
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 14px;
                    color: rgba(0, 0, 0, 0.5);
                    margin-bottom: 0;
                }
            }
        }
    }
    .team-options-container {
        width: 65%;
        background: #ececec;
        border-radius: 6px;
        padding: 30px 25px;
        h4.heading {
            font-weight: bold;
            font-size: 10px;
            text-transform: uppercase;
            color: rgba(0, 0, 0, 0.5);
        }
        &-heading {
            h4 {
                margin-top: 10px;
                font-weight: 600;
                font-size: 14px;
                color: #000000;
                margin-bottom: 0;
            }
            p {
                font-weight: 600;
                font-size: 10px;
                color: rgba(0, 0, 0, 0.5);
                margin-top: 5px;
                margin-bottom: 5px;
            }
            label {
                margin-top: 10px;
            }
        }
        hr {
            border: 0.5px solid rgba(0, 0, 0, 0.08);
        }
    }
}
