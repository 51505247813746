.note-container {
    background: #f3f3f3;
    border-radius: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    width: 100%;
    & img {
        margin-right: 10px;
    }
}
