.video-tour-label {
    width: 66px;
    height: 15px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: #fff;
    .dot {
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: #ff0000;
    }
    span {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 7px;
        color: grey;
        line-height: 1px;
    }
}
