.upload-video-container {
    width: calc(100% + 50px);
    height: 100%;
    display: flex;
    overflow: hidden;
    overflow-x: scroll;
    border: none;
    box-sizing: border-box;
    width: 100%;
    height: 590px;
    display: flex;
    // justify-content: center;
    align-items: center;
    position: relative;
    &::-webkit-scrollbar {
        height: 2px !important;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        width: 2px !important;
        border-radius: 10px;
        background-color: #adadad;
    }
    &::-webkit-scrollbar-track {
        background: #dcdcdc !important;
        width: 2px !important;
    }

    & .videos-wrapper {
        & .video-uploader {
            &:first-child {
                margin-left: 25px;
            }
        }
    }
    .add-more-card {
        width: 202px;
        min-width: 200px;
        max-width: 202px;
        height: 360px;
        min-height: 360px;
        max-height: 360px;
        // transform: scale(0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0.5;
        box-sizing: border-box;
        position: relative;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        margin-right: 30px;
        img {
            height: 50px;
            width: 50px;
            opacity: 0.4;
            cursor: pointer;
        }
        .title {
            position: absolute;
            bottom: 10px;
            padding: 0px 10px;
            text-align: center;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
        }
    }

    .drag-title {
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        opacity: 0.2;
        position: fixed;
        top: 652px;
        left: 42%;
    }
    .upload-video-icon-wrapper {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // Uploading Video Icon
    .upload-video-icon {
        height: 360px;
        width: 202px;
        box-sizing: border-box;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        opacity: 0.5;
        // transform: scale(0.9);
        img {
            height: 40px;
            width: 40px;
            opacity: 0.3;
            cursor: pointer;
        }
        .title {
            position: absolute;
            bottom: 10px;
            padding: 0px 10px;
            text-align: center;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    // Styles for Uploading Video Wrapper
    .videos-wrapper {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0 0 0 25px;
    }
}

// Bottom wrapper for uploading Video
.upload-bottom-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 35%;
    background-color: rgba(255, 255, 255, 0.32);
    width: 100%;
    z-index: 100;
    display: flex;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 40px 48px;
    height: 260px;
    box-sizing: border-box;
    .upload-info {
        .title-wrapper {
            display: flex;
            align-items: center;
            span {
                display: flex;
                align-items: center;
                justify-content: center;
                background: rgba(0, 0, 0, 0.12);
                border-radius: 4px;
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
                width: 45px;
                height: 22px;
            }
            .title-wrapper-price {
                margin-left: 10px;
                font-weight: 700;
                font-size: 18px;
                // opacity: 0.1;
                color: rgba(0, 0, 0, 0.5);
            }
        }

        .space-details {
            margin-top: 12px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: rgba(0, 0, 0, 0.5);
            height: 16px;
            box-sizing: border-box;
            & span {
                display: inline-block;
                margin: 0 3px;
                margin-top: -8px;
                vertical-align: middle;
            }
        }
        .address-title {
            font-size: 12px;
            font-weight: 700;
            line-height: 14px;
            margin-top: 10px;
        }
        .assignee-detail {
            margin-top: 20px;
            display: flex;
            align-items: center;
            background-color: white;
            width: auto;
            border-radius: 18px;
            min-width: 153px;
            width: 153px;
            h2 {
                margin-left: 5px;
                font-size: 12px;
                line-height: 16px;
                font-weight: 700;
            }
        }
    }

    button {
        position: absolute;
        right: 32px;
        bottom: 32px;
        background-color: black;
        border: none;
        color: white;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        height: 48px;
        width: 172px;
        border-radius: 6px;
        &:disabled {
            background-color: #bfbfbf;
        }
    }
}

.skill-edit-btn-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 30px;
    right: 30px;
    .button-edit-link {
        border: 1px solid rgba(0, 0, 0, 1);
        padding: 9px;
        display: flex;
        align-items: center;
        font-weight: 700;
        opacity: 0.7;
        border-radius: 6px;
        font-size: 10px;
        img {
            margin-right: 5px;
            height: 12px;
            width: 12px;
        }
    }
    .button-done {
        padding: 10px;
        display: flex;
        align-items: center;
        font-weight: 700;
        opacity: 0.7;
        border-radius: 6px;
        background: white;
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
        font-size: 10px;
        img {
            width: 10px;
            margin-right: 5px;
        }
    }
    .button-delete {
        display: flex;
        align-items: center;
        padding: 6px;
        border-radius: 6px;
        font-weight: 700;
        border: 1px solid rgba(224, 0, 0, 1);
        color: rgba(224, 0, 0, 1);
        font-size: 10px;
    }
}
