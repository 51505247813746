.issue-container {
    width: 100vw;
    box-sizing: border-box;
    height: 100vh;
    padding: 40px 10px 0px 10px;
    position: absolute;
    top: 67px;
}

.header {
    width: 100%;
    display: flex;
    column-gap: 20px;
    align-items: center;
}

.date-container {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    & label {
        font-weight: 700;
        font-size: 9px;
        line-height: 11px;
    }
}
.search-icon {
    height: 30px;
    cursor: pointer;
}

.main-table {
    width: 100%;
    margin-top: 50px;
    table-layout: fixed;
    border-collapse: separate;
}
th {
    border-bottom: 1px solid black;
}

tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    width: 100%;
    overflow: scroll;
}

td {
    // text-align: center;
    & input {
        height: auto !important;
        width: auto !important;
    }
    margin: 0;
    font-size: 12px;
    border-bottom: 1px solid black;
    border-right: 1px solid black;

    :last-child {
        border-right: 0;
    }
}

// pagination Wrapper
.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.pagination-wrapper ul {
    display: flex;
    align-items: center;
    justify-content: center;
}
.body-headers {
    display: grid;
    grid-template-columns: 1fr 2fr 0.5fr 0.5fr 0.5fr 3fr 1fr;
}
.body-content {
    & li {
        display: grid;
        grid-template-columns: 1fr 2fr 0.5fr 0.5fr 0.5fr 3fr 1fr;
    }
}

// Paginate Container

.paginate-container {
    display: flex;
    align-items: center;
    margin-top: 30px !important;
    margin-bottom: 45px !important;
}
.page-item {
    height: 30px;
    width: 30px;
    margin: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: white;
    transition: all 0.15s ease-in;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.27);
    border-radius: 5px;
}

.page-item:hover {
    color: rgba(0, 0, 0, 0.67);
}

.page-item-active {
    background-color: black;
    color: white;
}

.page-item-active:hover {
    background-color: black;
    color: white;
}

.page-item-link {
    border: 0px;
    cursor: pointer;
    outline: none;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 700;
    // &:hover {
    //   background-color: black;
    //   color: white;
    //   transform: scale(1.1);
    // }
    // &:active {
    //   transform: scale(0.9);
    // }
    border-radius: 5px;
}

.next-previous-class {
    text-align: center;
    margin: 0px 15px;
    width: 30px;
    border-radius: 5px;
    transition: all 0.15s ease-in-out;
    border: 0px;
    cursor: pointer;
    outline: none;
}

.next-previous-link-class {
    outline: none;
    width: 30px;
    height: 30px;
    color: rgba(0, 0, 0, 0.27);
}

.loading-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
