.stats-card-graph {
    position: relative;
    height: 272px;
    min-height: 272px;
    background: rgba(255, 255, 255, 0.72);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-sizing: border-box;
    padding: 24px;
    grid-column: span 2;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
    &:hover {
        -webkit-box-shadow: 10px 10px 68px -34px rgba(0, 0, 0, 0.3);
        -moz-box-shadow: 10px 10px 68px -34px rgba(0, 0, 0, 0.3);
        box-shadow: 10px 10px 40px -20px rgba(0, 0, 0, 0.3);
        background: rgba(255, 255, 255, 1);
    }
    .stats-card-graph-content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: space-between;
        position: relative;
        .stats-card-graph-header {
            width: 100%;
            display: flex;
            align-items: center;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            justify-content: space-between;
            .icon-wrapper {
                height: 35px;
                width: 35px;
                border-radius: 100%;
                margin-right: 15px;
                background-color: rgba(0, 0, 0, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    height: 20px;
                    width: 20px;
                }
            }
        }
        .stats-card-graph-wrapper {
            position: absolute;
            top: 20%;
            left: 50px;
            width: 90%;
            height: 90%;
            overflow: hidden;
            pointer-events: none;
            cursor: pointer;

            .no-data {
                font-size: 20px;
                line-height: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                font-weight: 700;
            }
        }
    }
}
.stats-card-graph-header-img {
    height: 12px;
    width: 11px;
}
