.android-tv-preivew {
    width: 310px;
    height: 490px;
    box-sizing: border-box;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
    display: none;
    position: relative;
    backdrop-filter: blur(70px);
    border: 8px solid #000;
    -webkit-box-shadow: 0px 29px 105px -16px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 29px 105px -16px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 29px 105px -16px rgba(0, 0, 0, 0.75);

    & .video-component {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0px;
        left: 0px;

        z-index: -1;
    }
    & .tv-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        & img {
            width: 55px;
            height: 6px;
        }
        & h2 {
            font-style: normal;
            font-weight: 800;
            font-size: 38px;
            line-height: 48px;
            margin: 0px;
            text-align: center;
            letter-spacing: 2.66517px;
            color: #ffffff;
            text-shadow: 0px 3.94444px 15.7778px rgba(255, 255, 255, 0.3);
        }
    }

    & .tv-footer {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-style: normal;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-items: center;
        & .otp-field {
            font-weight: 800;
            font-size: 21.8318px;
            line-height: 27px;
            letter-spacing: 1.55008px;
        }
        & .otp-verification {
            font-weight: 500;
            font-size: 9px;
            line-height: 10px;
            text-align: center;
            letter-spacing: -0.01em;
        }
    }

    & .space-details {
        position: absolute;
        left: 12px;
        bottom: 16px;
        width: 100%;
        box-sizing: border-box;
        .space-type {
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 700;
            color: rgba(0, 0, 0, 0.4);
            background-color: rgba(255, 255, 255, 0.44);
            border-radius: 2.5px;
            padding: 2px 6px;
            display: inline-block;
        }
        .price-container {
            margin-top: 5px;
            font-weight: 800;
            font-size: 12px;
            line-height: 12px;
            color: #fff;
            z-index: 2;
            display: flex;
            column-gap: 4px;
            align-items: flex-end;
            .currency-type {
                font-weight: 700;
                color: #fff;
                font-size: 8px;
                line-height: 9px;
            }
            .price-type {
                font-weight: 700;
                color: rgba(255, 255, 255, 0.72);
                font-size: 8px;
                line-height: 9px;
            }
        }
        .fs-address {
            margin-top: 4px;
            font-weight: 800;
            color: rgba(255, 255, 255, 0.72);
            z-index: 2;
            font-size: 7px;
            // margin-top: 4px;
            max-width: 175px;
        }
        .space-props {
            margin-top: 5px;
            & img {
                width: 105px;
            }
        }
        .space-qr {
            width: 50px;
            height: 50px;
            position: absolute;
            bottom: 0px;
            right: 20px;
            & img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
.android-tv-preivew::after {
    content: '';
    width: 15px;
    height: 400px;
    background: linear-gradient(to bottom, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
    display: block;
    position: absolute;
    bottom: -408px;
    left: 50%;
    transform: translateX(-50%);
}

// media query
@media only screen and (min-width: 768px) {
    .android-tv-preivew {
        display: block !important;
    }
}
