.add-more-field {
    margin-top: 32px;
}
.arrow-icon-invite-email {
    width: 14px !important;
    height: 6px !important;
}
.more-invite-fields {
    margin-top: -18px;
    margin-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
    background: #ececec;
    box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(56px);
    border-radius: 6px;
    height: 110px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .label-style {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #000000;
        margin-bottom: 6px;
    }
    & .container {
        background: #ffffff;
        border: 1px solid #e6e6e6;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 16px;
        box-sizing: border-box;
        position: relative;
        cursor: pointer;
        &.blocked-select {
            background: #bfbfbf;
            color: rgba(0, 0, 0, 0.5) !important;
        }
        &.dept-container {
            width: 123px;
            height: 44px;
        }
        &.team-container {
            width: 206px;
            height: 44px;
        }
        &.perm-container {
            width: 123px;
            height: 44px;
        }
    }
}
.invite-container {
    width: 100%;
}
.invite-li {
    position: relative;
    display: flex;
    & .close-btn {
        width: 24px;
        height: 24px;
        position: absolute;
        right: -40px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 100px;
    }
}

.top-row {
    position: relative;
}
.selected-permission {
    position: absolute;
    padding: 4px;
    top: 35px;
    right: 45px;
    color: #ffffff;
    text-transform: uppercase;
    border-radius: 2.26573px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    cursor: pointer;
    &.member {
        background: rgba(0, 0, 0, 0.3);
    }
    &.admin {
        background: #000000;
    }
}

.component-container {
    // box-sizing: border-box;
    // overflow: auto;
    // height: calc(100% - 112px);
    width: 100%;
    box-sizing: border-box;
    &::-webkit-scrollbar {
        width: 4px !important;
        display: block !important;
    }
    &::-webkit-scrollbar-thumb {
        width: 4px !important;
        border-radius: 10px;
        background-color: #adadad;
    }
    &::-webkit-scrollbar-track {
        background: #dcdcdc !important;
        width: 4px !important;
    }
}

.email-input {
    padding-right: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
