.bottom-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 112px !important;
    background-color: #ffffff52;
    width: 100%;
    z-index: 100;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;
    backdrop-filter: blur(17.5px);
    a {
        font-weight: 700;
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
        color: #e00000;
        cursor: pointer;
    }
    button {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        background-color: #000;
        border: none;
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        font-weight: 700;
        height: 40px;
        border-radius: 6px;
    }
}
