.one-time-container {
  @include media(">=phone", "<=lgphone") {
    width: 100%;
    height: 100%;
    flex-direction: column-reverse;
    border-radius: 0px;
    .main-logo {
      display: block;
    }
    .banner-container {
      width: 80%;

      ol {
        li {
          font-size: 14px;
          white-space: normal;

          .point {
            .download-btn {
              img {
                width: 100%;
                object-fit: contain;
              }
            }
            .white-btn {
              width: 100%;
            }
          }
        }
      }
    }
    .banner-image-wrapper {
      width: 60%;
      height: auto;
    }
  }
  @include media(">=lgphone", "<=tablet") {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    .main-logo {
      display: block;
      top: 80px;
      left: 60px;
    }
    .banner-container {
      width: 80%;
      margin-left: 60px;

      ol {
        width: 70%;

        li {
          font-size: 14px;
          white-space: normal;

          .point {
            .download-btn {
              img {
                width: 100%;
                object-fit: contain;
              }
            }
            .white-btn {
              width: 100%;
            }
          }
        }
      }
    }
    .banner-image-wrapper {
      width: 60%;
      height: auto;
    }
  }
  @include media(">=tablet", "<=desktop") {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    .main-logo {
      display: block;
      top: 170px;
      left: 60px;
    }
    .banner-container {
      width: 80%;
      margin-left: 60px;

      ol {
        width: 70%;

        li {
          font-size: 14px;
          white-space: normal;

          .point {
            .download-btn {
              img {
                width: 100%;
                object-fit: contain;
              }
            }
            .white-btn {
              width: 100%;
            }
          }
        }
      }
    }
    .banner-image-wrapper {
      width: 60%;
      height: auto;
    }
  }
}
