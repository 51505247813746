.space-card-wrapper {
    width: 900px;
    min-width: 800px;
    max-width: 950px;
    height: 760px;
    min-height: 680px;
    max-height: 760px;
    box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.05);
    padding: 25px;
    border-radius: 6px;
    background: rgba(225, 225, 225, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    .top-wrapper-edit {
        position: fixed;
        height: 57px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        justify-content: space-between;
        z-index: 2;
        box-sizing: border-box;
        padding: 10px 25px 0 25px;
        backdrop-filter: blur(17.5px);

        .back-button {
            height: 32px;
            width: 32px;
            cursor: pointer;
            z-index: 100;
            border-radius: 100%;
            box-shadow: 0px 5px 10px 0px rgba($color: #000000, $alpha: 0.2);
            pointer-events: auto;
            img {
                height: 32px;
                width: 32px;
                cursor: pointer;
            }
        }
        .edit-btn-group {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .button-edit-link {
                border: 1px solid rgba(0, 0, 0, 1);
                padding: 9px;
                display: flex;
                align-items: center;
                font-weight: 700;
                opacity: 0.7;
                border-radius: 6px;
                font-size: 10px;
                img {
                    margin-right: 5px;
                    height: 12px;
                    width: 12px;
                }
            }
            .button-done {
                padding: 10px;
                display: flex;
                align-items: center;
                font-weight: 700;
                opacity: 0.7;
                border-radius: 6px;
                background: white;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.15);
                font-size: 10px;
                img {
                    width: 10px;
                    margin-right: 5px;
                }
            }
            .button-delete {
                display: flex;
                align-items: center;
                padding: 6px;
                border-radius: 6px;
                font-weight: 700;
                border: 1px solid rgba(224, 0, 0, 1);
                color: rgba(224, 0, 0, 1);
                font-size: 10px;
            }
        }
        .divider {
            margin-left: 20px;
            margin-right: 20px;
            width: 1px;
            height: 26px;
            background: rgba(0, 0, 0, 0.1);
        }
    }
    .mid-wrapper-edit {
        padding-top: 60px;
        width: 65%;
        overflow-y: scroll;
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 50px;
        .toggler-space {
            width: 100%;
            height: 68px;
            top: 0;
            left: 0;
            position: absolute;
            cursor: pointer;
        }
        &-extra {
            height: 110px;
            width: 100%;
        }
        &-space {
            position: relative;
            border-radius: 12px;
            padding: 25px 25px 35px 25px;
            background-color: #fff;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
            &-image {
                top: -40px;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 135px;
                width: 90px;
                position: absolute;
                border: 3px solid #fff;
                border-radius: 12px;
                cursor: pointer;
                filter: drop-shadow(0px 40px 40px rgba(0, 0, 0, 0.25));
                img {
                    border-radius: 10px;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
                span.video-skill {
                    top: 2px;
                    left: 2px;
                    position: absolute;
                    font-weight: 800;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 8px;
                    background-color: #6b6b6bb8;
                    padding: 3px 7px;
                    border-radius: 25px;
                }
            }
            &-image::after {
                position: absolute;
                bottom: -17px;
                left: 50%;
                transform: translate(-50%, -50%);
                content: '';
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;

                border-top: 10px solid #fff;
            }

            &-price {
                margin-top: 12px;
                color: #000;
                font-weight: 700;
                span.currency {
                    font-size: 12px;
                }
                span.price {
                    font-size: 16px;
                }
                span.no-price {
                    font-size: 16px;
                    color: #0000001a;
                }
                span.month {
                    font-size: 12px;
                    color: #00000080;
                }
            }
            &-details {
                margin-top: 10px;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.5);
                line-height: 16px;
                font-weight: 600;
                width: 52%;
            }
            .space-type-status {
                display: flex;
                column-gap: 0px;
                align-items: center;
                margin-top: 20px;
                & span {
                    display: block;
                    height: 20px;
                }
            }
            .space-type {
                color: #fff;
                font-size: 10px;
                font-weight: 700;
                background: rgba(0, 0, 0, 0.08);
                backdrop-filter: blur(4px);
                color: #000000;
                padding: 4px 6px;
                border-radius: 2.5px;
                margin-right: 8px;
                display: flex;
                column-gap: 6px;
                text-transform: uppercase;
                &.unpublished {
                    background: rgba(255, 0, 0, 0.08);
                    backdrop-filter: blur(4px);
                    color: #e00000;
                }
            }
            &-comp {
                margin-top: 10px;
                display: flex;
                align-items: center;

                .space-comp {
                    display: flex;
                    align-items: center;
                    margin-right: 8px;
                    span.text {
                        color: #00000080;
                        font-size: 12px;
                        font-weight: 700;
                    }
                }
            }
        }
        &-agent {
            position: relative;
            border-radius: 12px;
            padding: 25px;
            background-color: #fff;
            margin-top: 25px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
            h4 {
                margin: 0;
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
            }
            img.caret {
                float: right;
            }
            &-container {
                margin-top: 20px;
                padding: 10px 0;
                &-details {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    &-profile {
                        display: flex;
                        align-items: center;
                        .agent-img {
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;
                            img {
                                border-radius: 50%;
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                        .agent-name {
                            margin-left: 15px;
                            display: flex;
                            flex-direction: column;
                            height: 42px;
                            justify-content: space-evenly;
                            h4 {
                                font-size: 14px;
                                font-weight: 700;
                            }
                            span {
                                color: #00000080;
                                font-size: 12px;
                                font-weight: 600;
                            }
                        }
                    }
                    &-contacts {
                        display: flex;
                        padding: 8px;
                        background-color: #fff;
                        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                        border-radius: 25px;
                        .cta-btn {
                            position: relative;
                            display: flex;
                            img {
                                height: 24px;
                                width: 24px;
                                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
                                border-radius: 50%;
                            }
                            & .tooltip {
                                position: absolute;
                                top: -40px;
                                left: 50%;
                                transform: translateX(-50%);
                                padding: 10px 12px;
                                background: #ffffff;
                                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
                                border-radius: 4px;
                                font-weight: 700;
                                font-size: 10px;
                                line-height: 12px;
                                color: #000000;
                                display: none;
                                border-radius: 25px;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    width: 0;
                                    height: 0;
                                    top: 32px;
                                    border-left: 5px solid transparent;
                                    border-right: 5px solid transparent;
                                    border-top: 5px solid #fff;
                                }
                            }
                            &:hover {
                                & .tooltip {
                                    display: flex;
                                    justify-content: center;
                                }
                            }
                        }
                        .cta-btn:not(:last-child) {
                            margin-right: 8px;
                        }
                    }
                }
                &-message {
                    margin-top: 30px;
                    h4 {
                        text-transform: uppercase;
                        font-size: 10px;
                        color: #000;
                        font-weight: 700;
                        display: block;
                    }
                    span {
                        margin-top: 10px;
                        font-size: 12px;
                        font-weight: 600;
                        color: #00000080;
                        display: block;
                        word-break: break-word;
                    }
                }
            }
        }
        &-pair {
            position: relative;
            border-radius: 12px;
            padding: 25px;
            background-color: #fff;
            margin-top: 25px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
            h4 {
                margin: 0;
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
            }
            img.caret {
                float: right;
            }
            &-container {
                padding: 10px 0;
                span {
                    font-size: 12px;
                    font-weight: 600;
                    color: #00000080;
                }
                &-input {
                    margin-top: 30px;
                    input {
                        box-shadow: none;
                        border: 1px solid rgba(0, 0, 0, 0.06);
                        background: rgba(0, 0, 0, 0.08);
                    }
                    .success-paired {
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: #c9ffc4;
                        border-radius: 8px;
                        width: 100%;
                        padding: 16px 0;

                        span {
                            font-size: 10px;
                            font-weight: 700;
                            margin: 0 10px 0 5px;
                        }
                        a {
                            cursor: pointer;
                            font-size: 10px;
                            font-weight: 700;
                            text-transform: uppercase;
                            text-decoration: underline;
                            color: #00000080;
                        }
                        .fail-paired {
                            margin: 0 10px 0 5px;
                            width: 350px;
                            h4 {
                                display: block;
                                font-size: 10px;
                                font-weight: 700;
                                color: #00000080;
                            }
                            span {
                                line-height: 14px;
                                font-size: 10px;
                                color: #00000052;
                                font-weight: 600;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
        &-direct {
            position: relative;
            border-radius: 12px;
            padding: 25px;
            background-color: #fff;
            margin-top: 25px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
            h4 {
                margin: 0;
                font-size: 16px;
                font-weight: bold;
                display: inline-block;
            }
            img.caret {
                float: right;
            }
            &-container {
                padding: 10px 0;
                span {
                    font-size: 12px;
                    font-weight: 600;
                    color: #00000080;
                }
                &-input {
                    margin-top: 30px;
                    position: relative;
                    input {
                        box-shadow: none;
                        border: 1px solid rgba(0, 0, 0, 0.06);
                        background: rgba(0, 0, 0, 0.08);
                        padding: 16px 100px 16px 12px;
                    }
                    button {
                        font-weight: 700;
                        font-size: 10px;
                        border: 1px solid #0000008f;
                        padding: 8px;
                        border-radius: 6px;
                        position: absolute;
                        right: 6px;
                        top: 5px;
                        color: #0000008f;
                    }
                }
            }
        }
        &-private {
            position: relative;
            border-radius: 12px;
            padding: 25px 25px 5px 25px;
            background-color: #fff;
            margin-top: 25px;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.05);
        }
    }

    .total-videos {
        color: #fff;
        border-radius: 35px;
        padding: 4px 8px;
        background-color: rgba(107, 107, 107, 0.72);
        font-size: 10px;
        display: block;
        text-align: center;
        font-weight: 800;
        align-self: flex-start;
        // margin: 15px 0 0 30px;
        text-transform: uppercase;
        position: absolute;
        top: 72px;
        left: 52px;
    }
    span.click-drag-text {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        font-weight: 700;
        color: #b4b4b4;
        top: 61.6%;
    }
    .video-container {
        position: relative;
        display: flex;
        width: 100%;
        margin-top: 0px;
        overflow: hidden;
        overflow-x: scroll;
        // padding: 20px 25px 50px 25px;
        height: 592px;

        box-sizing: border-box;
        display: flex;
        align-items: center;
        &::-webkit-scrollbar {
            height: 2px !important;
            display: block !important;
        }
        &::-webkit-scrollbar-thumb {
            width: 2px !important;
            border-radius: 10px;
            background-color: #adadad;
        }
        &::-webkit-scrollbar-track {
            background: #dcdcdc !important;
            width: 8px !important;
        }
        &-item {
            position: relative;
            height: 360px;
            box-sizing: border-box;
            border-radius: 10px;
            box-shadow: 0px 10px 30px 0px #00000026;
            background-color: white;
            margin-right: 20px;
            background-size: cover;
            background-position: center;
            transition: all 0.5s ease-in-out;
            width: 202px;
            &-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                font-size: 10px;
                font-weight: 700;
                background-color: rgba(0, 0, 0, 0.56);
                color: white;
                position: absolute;
                top: 47%;
                left: 50%;
                transform: translate(-50%, -50%);
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
                width: 110px;
                height: 30px;
                padding: 2px;
            }
            &-desc {
                z-index: 2;
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 40px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                display: flex;
                align-items: center;
                background-color: #fff;
                img {
                    margin-left: 4px;
                    cursor: pointer;
                    padding: 11px;
                }
                input {
                    outline: none;
                    font-size: 12px;
                    box-shadow: none;
                    background-color: transparent;
                    color: #000;
                    height: auto;
                    width: auto;
                    border: unset;
                    padding: 1px;
                    &::-webkit-input-placeholder {
                        color: #000;
                    }
                }
            }
            &-dur {
                position: absolute;
                bottom: 50px;
                left: 15px;
                color: #fff;
                font-size: 10px;
                font-weight: bold;
            }
        }
        &-item:nth-child(1) {
            margin-left: 30px;
        }

        // Uploading Video Icon

        .upload-video-icon-container {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .upload-video-icon {
            height: 360px;
            min-height: 320px;
            width: 202px;
            min-width: 190px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
            opacity: 0.5;
            // transform: scale(0.9);
            cursor: pointer;
            img {
                height: 40px;
                width: 40px;
                opacity: 0.3;
            }
            .title {
                position: absolute;
                bottom: 10px;
                padding: 0px 10px;
                text-align: center;
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
            }
        }
        .drag-title {
            position: fixed;
            bottom: 113px;
            left: 42%;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            opacity: 0.2;
            top: 672px;
        }
    }

    // Bottom wrapper
    .bottom-wrapper-edit {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 260px;
        min-height: 260px;
        background-color: rgba(255, 255, 255, 0.32);
        width: 100%;
        z-index: 100;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        .upload-info {
            width: 50%;
            position: relative;
            padding: 20px;
            margin: 10px;
            border-radius: 9px;
            .upload-info-edit-icon {
                position: absolute;
                right: 10px;
                top: 20px;
            }
            .title-wrapper {
                display: flex;
                align-items: center;
                span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(0, 0, 0, 0.12);
                    border-radius: 4px;
                    font-size: 10px;
                    line-height: 14px;
                    font-weight: 700;
                    width: 55px;
                    height: 22px;
                }
                .title-wrapper-price {
                    margin: 0;
                    margin-left: 10px;
                    font-weight: 700;
                    font-size: 18px;
                    opacity: 0.5;
                }
            }
            .address-title {
                font-size: 12px;
                font-weight: 700;
                line-height: 14px;
                margin-top: 10px;
            }
            .space-info {
                font-size: 12px;
                line-height: 16px;
                font-weight: 600;
                opacity: 0.5;
                text-transform: lowercase;
            }

            .assignee-detail {
                display: flex;
                align-items: center;
                background-color: white;
                box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.1);
                border-radius: 18px;
                min-width: 143px;
                max-width: 143px;
                width: 143px;
                cursor: pointer;
                box-sizing: border-box;
                padding-left: 10px;
                .assignee-icon {
                    width: 24px;
                    height: 24px;
                    object-fit: cover;
                    border-radius: 100%;
                }
                h2 {
                    margin-left: 5px;
                    font-size: 12px;
                    line-height: 16px;
                    font-weight: 700;
                }
            }
        }
        .cta-wrapper {
            display: flex;
            align-items: center;
            margin: 0;
            margin-top: 15px;
            .cta-btn {
                margin: 0;
                width: 32px;
                height: 32px;
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
                margin-left: 10px;
                cursor: pointer;
                position: relative;
                img {
                    width: 16px;
                    height: 16px;
                }
            }
        }

        .created-at-wrapper {
            display: flex;
            align-items: center;
            img {
                width: 13px;
                height: 13px;
                margin-right: 5px;
            }
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            opacity: 0.5;
            text-transform: uppercase;
            border-top: 1px solid rgba(0, 0, 0, 0.144);
            margin-top: 20px;
            padding-top: 20px;
            max-width: 360px;
        }
        .second-half {
            width: 50%;
            padding: 20px;
            margin: 10px;
            .input-container {
                width: 100%;
                .input-wrapper {
                    display: flex;
                    align-items: center;
                    background-color: white;
                    border-radius: 6px;
                    overflow: hidden;
                    button {
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 12px;
                        border: 1px solid black;
                        padding: 8px;
                        border-radius: 6px;
                        margin-right: 5px;
                    }
                    .input-btn-cancel {
                        width: 20px;
                        height: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: none;
                        img {
                            width: 15px;
                            height: 15px;
                        }
                    }
                }

                h2 {
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 12px;
                    white-space: nowrap;
                    display: flex;
                    align-items: center;

                    img {
                        width: 15px;
                        height: 15px;
                        border: none;
                        margin-right: 5px;
                    }
                }
                input {
                    width: 100%;
                    font-weight: 700;
                    font-size: 12px;
                    line-height: 12px;
                    background: transparent !important;
                    border: none !important;
                    box-shadow: none !important;
                }
                .input-error {
                    margin-top: 10px;
                    font-size: 10px;
                    color: rgba(224, 0, 0, 1);
                    font-weight: 700;
                }
            }
        }
    }
}

.delete-popup-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
}
// Delete Pop Up
.delete-popup {
    background: rgba(246, 101, 90, 0.8);
    backdrop-filter: blur(10.5px);
    -webkit-backdrop-filter: blur(10.5px);
    border-radius: 12px;
    width: 316px;
    height: 258px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        width: 80%;
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        color: white;
    }
    h2 {
        width: 80%;
        margin: 0;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 600;
        color: white;
    }
    .btn-wrapper {
        display: flex;
        align-items: center;
        width: 80%;
        margin-top: 60px;
        justify-content: space-between;
        button {
            padding: 15px;
            font-size: 12px;
            font-weight: 700;
            color: white;
            cursor: pointer;
            &:first-child {
                background-color: white;
                border-radius: 6px;
                color: rgba(246, 101, 90, 1);
            }
            &:last-child {
                margin-left: 20px;
            }
        }
    }
}

.drag-on {
    width: 100%;
    height: 534px;
    background: rgba(0, 0, 255, 0.05);
    border: 1px solid #0000ff;
    z-index: -1;
    position: absolute;
    top: 0px;
    left: 0px;
    box-sizing: border-box;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}

.private-status {
    width: 18px;
    height: 18px !important;
    background-color: rgba(0, 0, 0, 0.08) !important;
    position: relative;
    padding: 0px;
    border-radius: 4px !important;
    & img {
        width: 18px !important;
        height: 18px !important;
        object-fit: contain;
    }
}

.section-title {
    display: flex;
    justify-content: space-between;
    h4 {
        margin: 0;
        font-size: 16px;
        font-weight: bold;
    }
    h6 {
        margin: 0;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: rgba(0, 0, 0, 0.56);
        cursor: pointer;
    }
}
