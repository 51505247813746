// Empty State Teams

.empty-state-teams {
    overflow: hidden;
    background-color: white;
    height: 600px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    .empty-state-image-wrapper {
        width: 50%;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        background: url('../../assets/empty-state-teams-bg.png');
        background-size: cover;
        background-repeat: no-repeat;

        img {
            width: 470px;
            margin: 0;
        }
        h1 {
            font-size: 24px;
            line-height: 28px;
            font-weight: 700;
            margin: 0;
            margin-top: 20px;
        }
    }
    .empty-state-content {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
